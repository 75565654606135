import { errorAlert, loadingAlert, successAlert } from '../../../../utils/alert';
import { api } from '../../../api';
import { UseMutationResult, useMutation } from 'react-query';

//=================================================================================>
// DELETAR COMPATIBILIDADE
//<=================================================================================

interface ParamsDeleteCompat {
    cod?: number,
    codConta: number;
    limparStates: () => void;
    atualizarLista: Function;
    closePopup: Function;
}

export const useDeleteCompatibilidade = (): {
    carregando_deletar_compati: boolean;
    mutate_deletar_compati: (params: ParamsDeleteCompat) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsDeleteCompat> = useMutation(
        async (params: ParamsDeleteCompat) => {
            const { codConta, atualizarLista, limparStates, cod, closePopup } = params;

            loadingAlert({msg: 'Excluindo...', id: 'loading'});

            return api.delete(`/recomendacao/compatibilidade/${cod}?codConta=${codConta}&embed=composicao`)
                .then((response: any) => {
                    successAlert('Excluído com sucesso!', 5000, 'loading');
                    limparStates?.();
                    atualizarLista?.();
                    closePopup?.();
                    return response;
                })
                .catch((error: Error) => {
                    errorAlert('Falha ao excluir compatibilidade', 7000, 'loading');
                    throw error;
                });
        }
    );

    return { 
        carregando_deletar_compati: mutation.isLoading, 
        mutate_deletar_compati: mutation.mutate 
    };
};

//=================================================================================>
// DELETAR COMPATIBILIDADE EM MASSA
//<=================================================================================

interface ParamsDeleteCompatEmMassa {
    cod?: number,
    codConta: number;
    atualizarLista: Function;
    atualizarListaPrincipal: Function;
    limmparCheckBox: Function;
    ultimaChamada: boolean;
    closePopup: Function;
}

export const useDeleteCompatibilidadeEmMassa = (): {
    carregando_deletar_compati: boolean;
    mutate_deletar_compati: (params: ParamsDeleteCompatEmMassa) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsDeleteCompatEmMassa> = useMutation(
        async (params: ParamsDeleteCompatEmMassa) => {
            const { codConta, atualizarLista, atualizarListaPrincipal, cod, limmparCheckBox, ultimaChamada, closePopup } = params;

            loadingAlert({msg: 'Salvando...', id: 'loading'});

            return api.delete(`/recomendacao/compatibilidade/${cod}?codConta=${codConta}&embed=composicao`)
                .then((response: any) => {
                    if (ultimaChamada) {
                        successAlert('Compatibilidades excluídas com sucesso!', 5000, 'loading');
                        atualizarLista?.();
                        atualizarListaPrincipal?.();
                        limmparCheckBox();
                        closePopup();
                    }
                    return response;
                })
                .catch((error: Error) => {
                    errorAlert('Falha ao excluir compatibilidades', 7000, 'loading');
                    throw error;
                });
        }
    );

    return { 
        carregando_deletar_compati: mutation.isLoading, 
        mutate_deletar_compati: mutation.mutate 
    };
};
