import React, { useEffect, useState } from 'react'

import { Form, Input, Drawer, Button, Col, Row, Select } from 'antd'

import {
  postCategoriaMarca,
  putCategoriaMarca,
  getCategoriaMarca,
} from '../../services/config/marca';
import { useGetCategoriaMarca } from '../../services/config/marca/get';


import { Container } from './styled'
import { tiposGerais } from '../../services/Afins'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

interface TypesFormMarca {
  onShow: boolean
  onClose: (data: boolean) => void
  update: number
  setUpdate: (data: number) => void
  refresh: (data: string) => void
}

const { Option } = Select

const Marca: React.FC<TypesFormMarca> = (props) => {
  const { update, setUpdate, onShow, onClose, refresh } = props

  const [form] = Form.useForm()

  const [drawerVisible, setDrawerVisible] = useState(false)

  const [dataCategorias, setDataCategorias] = useState([]);

  //API
  const { data_categorias } = useGetCategoriaMarca();

  const listCategorias = async () => {
    const resp = await tiposGerais()

    setDataCategorias(resp)
  }

  const editMarca = async (data) => {
    
    loadingAlert({msg: 'Carregando...', id: 'get_cat_marca'});
    const response = await getCategoriaMarca(data, '', '', 'nome')
    const dados = response.data.itens[0]

    let categorias = []

    dados.categorias.forEach((info) => {
      categorias = [...categorias, info.cod_categoria]

      return categorias
    })

    form.setFieldsValue({
      nome: dados.nome,
      categorias,
    })
    
    destroyAlert('get_cat_marca');
  }

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setUpdate(0)
    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      loadingAlert({msg: 'Salvando...', id: 'salvando_marca'})
      if (update <= 0) {
        const response = await postCategoriaMarca(data)
        if (response.status === 201) {
          successAlert('Marca cadastrada com sucesso!', 5000, 'salvando_marca');
          form.resetFields()
          refresh('')
        }
      } else {
        const response = await putCategoriaMarca(update, data)
        if (response.status === 200) {
          successAlert('Marca atualizada com sucesso!', 5000, 'salvando_marca');
          refresh('')
        }
      }
    } catch (error) {

      if (error.response) {
        const { data } = error.response
        if (!data?.error?.[0].field) {
          errorAlert(data?.error ?? 'Falha ao registrar marca!', 5000, 'salvando_marca');
        } else {
          (data?.error ?? []).forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao registrar marca!', 5000, 'salvando_marca');
          })
        }
      }
      else {
        destroyAlert();
      }
    }
  }

  useEffect(() => {
    setDrawerVisible(onShow)
  }, [onShow])

  useEffect(() => {
    if (update !== 0) {
      editMarca(update)
    }
  }, [update])

  useEffect(() => {
    listCategorias()
  }, [])

  return (
    <Container>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Drawer
          title={update <= 0 ? 'Cadastro de Marca' : 'Atualizar Marca'}
          style={{ position: 'absolute' }}
          placement='right'
          width='100%'
          open={drawerVisible}
          closeIcon={false}
          onClose={hancleDrawerClose}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button type='primary' htmlType='submit'>
                {update ? 'Atualizar Marca' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          <Col span={22}>
            <Row gutter={[8, 8]}>
              <Col>
                <Form.Item name='nome' label='Nome Marca' rules={[{required: true}]}>
                  <Input placeholder='Digite o nome da marca' />
                </Form.Item>
              </Col>
              <Col flex='0 1 300px'>
                <Form.Item name='categorias' label='Categoria' rules={[{required: true}]}>
                  <Select
                    style={{ minWidth: '100%' }}
                    mode='tags'
                    showSearch
                    placeholder='Selecione uma Categoria'
                  >
                    {(data_categorias?.itens ?? []).map(({ cod, nome }) => (
                      <Option key={cod} value={cod}>
                          {nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Drawer>
      </Form>
    </Container>
  )
}

export default Marca
