import styled from 'styled-components'

export const ContainerGaveta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 15px;

  h2 {
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    color: #2d3436;
  }

  .informacoes__perfil {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    .img__perfil {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      h3 {
        padding: 0;
        margin: 0;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 600;
        color: #2d3436;
      }

      img {
        border-radius: 50%;
        width: 35%;
      }
    }

    .avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: bold;
      color: white;
      background-color: gray;
      overflow: hidden;
      position: relative;
      text-transform: uppercase;
    }

    .avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .avatar span {
      z-index: 1;
      font-size: 20px;
      font-weight: 600;
    }

    .nome{
      text-transform: capitalize;
      padding: 0;
      margin: 0;
    }

    .dados__aniversariantes {
      width: 100%;
      display: flex;
      gap: 10px;

      .idade__empresa {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 10px;

        p {
          font-size: 13px;
          padding: 0;
          margin: 0;
          color: #2d3436;
          display: flex;
          gap: 5px;
        }
      }
    }
  }
`