import React, { useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Empty, Tag } from 'antd';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//APP
import { removerAcentos } from '../../../../../utils';
import { ItensPerUserLogged } from '../../../../../services/login/permissaoUsuarioLoado';

//COMPONENTS
import Input from '../../../../../Components/CustomUi/input';
import Carregando from '../../../../../Components/CustomUi/loading';
import ButtonCustom from '../../../../../Components/ButtonCustom';
import GeneratePdfButton from '../../../../../Components/relatorio-component';
import { Box } from '../../../../../Components/CustomUi/box';
import { Inline } from '../../../../../Components/CustomUi/inline';
import { Render } from '../../../../../Components/CustomUi/render';
import { RowItem } from '../../../../../Components/CustomUi/table/rowItem';
import { RowTable } from '../../../../../Components/CustomUi/table/rowTable';
import { BodyTable } from '../../../../../Components/CustomUi/table/bodyTable';
import { HeaderTable } from '../../../../../Components/CustomUi/table/headerTable';
import { DeleteButton } from '../../../../../Components/CustomUi/deleteButton';

dayjs.extend(isBetween);

interface Props {
  loading: boolean
  list_safras: any[]
  deletar: Function
  abrir?: Function
  editar?: Function
  permissao: ItensPerUserLogged
  setPagination?: React.Dispatch<React.SetStateAction<number>>
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  total: number
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
  dadosEstatisticas: any[]
  dadosResultado: any[]
}

export const TabelaSafra: React.FC<Props> = ({
  loading,
  list_safras,
  deletar,
  abrir,
  editar,
  permissao,
  setPagination,
  page,
  setPage,
  total,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  fake_loading,
  setFake_loading,
  dadosEstatisticas,
  dadosResultado
}) => {
  const [buscarTabela, setBuscarTabela] = useState('')

  function buscarSafras(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      // console.log('item', item, buscar_nome)
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  function isVigente(data_inicio: string, data_final: string): React.ReactNode {
    let isProgramada = dayjs().isBefore(dayjs(data_inicio));
    let isSafraVigente = dayjs().isBetween(data_inicio, dayjs(data_final));

    if (isProgramada) {
      return <Tag color='blue'>Programada</Tag>
    }
    
    return isSafraVigente ? <Tag color='success'>Vigente</Tag> : <Tag color='error'>Encerrada</Tag>
  }

  return (
    <Box style={{ height: 'calc(100vh - 170px)', border: 'none' }} padding={0} borderless>
      {/* <TitleH2>Lista de patrimônios</TitleH2> */}

      <Inline alignItems='center'>
        <Input placeholder='Buscar safra' value={buscarTabela} onChange={setBuscarTabela} />
        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={abrir}>
          Novo
        </ButtonCustom>
        {localStorage.getItem('tipo-acesso') === 'conta' ? (
          <GeneratePdfButton
            dados={dadosEstatisticas}
            title={'Relatório da safra'}
            totais={dadosResultado}
          />
        ) : null}
      </Inline>

      <HeaderTable
        itens={[
          { valor: 'Nome da safra', width: 'calc(100% - 586px)' },
          { valor: 'Vigência', width: '100px' },
          { valor: 'Data início', width: '131px' },
          { valor: 'Data final', width: '129px' },
          { valor: 'Ações', width: '225px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: 'calc(100vh - 170px)',
          // height: (list_patrimonios ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(list_safras ?? [])
            .filter(buscarSafras(buscarTabela))
            .map(({ cod, nome, data_inicio, data_final }) => {
              return (
                <RowTable key={cod} onDoubleClick={() => editar(cod, false)}>
                  <RowItem fontSize={13} width={'calc(100% - 580px)'}>
                    {nome}
                  </RowItem>
                  <RowItem fontSize={13} width={'100px'}>
                    {isVigente(data_inicio, data_final)}
                  </RowItem>
                  <RowItem fontSize={13} width={'130px'}>
                    {dayjs(data_inicio).format('DD/MM/YYYY')}
                  </RowItem>
                  <RowItem fontSize={13} width={'130px'}>
                    {dayjs(data_final).format('DD/MM/YYYY')}
                  </RowItem>

                  <RowItem width={'220px'} gap={10}>
                    <DeleteButton
                      open={confirmar_exclusao && id_item_atual === cod}
                      trigger={setConfirmar_exclusao}
                      onTrigger={() => {
                        setId_item_atual(cod)
                        setNome_item_atual(nome)
                      }}
                      loading={fake_loading}
                      message={`o item ${nome} será excluido!`}
                      deleteds={[nome]}
                      onDelete={() => {
                        deletar(cod)
                      }}
                      disabled={fake_loading}
                      // disabledTrigger={permissao?.exclusao === 0}
                      disabledTrigger={permissao?.exclusao === 0}
                      textButtonTrigger='Excluir'
                      typeButtonTrigger='secondary'
                    />
                    <ButtonCustom
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={() => {
                        editar(cod, false)
                      }}
                      disabled={permissao?.alteracao === 0 && permissao?.acesso === 0}
                    >
                      Editar
                    </ButtonCustom>
                  </RowItem>
                </RowTable>
              )
            })}
        </Render>

        <Render condition={list_safras.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando safras...'
            justifyContent='center'
            height={400}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}
