import { EyeOutlined, UploadOutlined } from '@ant-design/icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { kml } from '@tmcw/togeojson'
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { setTimeout } from 'timers'
import {
  dummyRequest,
  fileTypeText,
  getBoundsNoArray,
  parseXml,
  tryError,
} from '../../../services/Afins'
import { putPedlaboratorio } from '../../../services/Laboratorio'
import { GeoJSONFeatureCollection } from '../../../types'
import Base64 from '../../Base64Geral'
import DatePicker from '../../DatePiker'
import Icons from '../../Icons'
import { geralKmlPolygon } from '../../gerarKml'
import { Notification } from '../../notification'
import UploadFile from '../../uploadFile'
import MapaUpdateContorno from '../MapUpdateContorno'
import { lista_cod_analises } from './meta'
import { successAlert } from '../../../utils/alert'

type Props = {
  dadosCliente: any
  inputRepresentante: any
  dataRepresentateLegal: any
  idRepresentanteLegal: any
  handleChangeRepresentanteLegal: (parms1: any, parms2) => void
  onFinish: (data: any) => void
  form: any
  permissions: any
  valueSolicitante: any
  handleOutrosSolicitantes: (data: any) => void
  handelChangePropriedade: (data: any) => void
  propriedadesMatricula: any
  propriedadesHa: any
  optPropriedades: any
  disableTalhaoSelect: any
  loadingTalhao: any
  handleSelectTalhao: any
  optTalhoes: any
  handelCalcAreaAmostra: (aAmostra: any, area: any) => void
  handelChangeLaboratorio: (data: any) => void
  optLaboratorio: any
  localConfig: any
  handleChangeSafraConsultoria: (data: any) => void
  optSafraConsultoria: any
  optEquipe: any
  handleAddAnalise: (data: any) => void
  uploadShape: any
  onChangeUpload: (data: any) => void
  dummyRequest: (options: any) => void
  loadingShape: any
  malha: any
  isModalVisibleMap: any
  handleOkMap: any
  disabledDate: (data: any) => boolean
  update: number
}

const { Option } = Select

const DataClienteOrder: React.FC<Props> = (props) => {
  const {
    dadosCliente,
    inputRepresentante,
    dataRepresentateLegal,
    idRepresentanteLegal,
    handleChangeRepresentanteLegal,
    form,
    onFinish,
    permissions,
    valueSolicitante,
    handleOutrosSolicitantes,
    propriedadesMatricula,
    propriedadesHa,
    handelChangePropriedade,
    optPropriedades,
    disableTalhaoSelect,
    loadingTalhao,
    handleSelectTalhao,
    optTalhoes,
    update,
    handelCalcAreaAmostra,
    handelChangeLaboratorio,
    optLaboratorio,
    localConfig,
    handleChangeSafraConsultoria,
    optSafraConsultoria,
    optEquipe,
    handleAddAnalise,
    uploadShape,
    onChangeUpload,
    loadingShape,
    malha,
    isModalVisibleMap,
    handleOkMap,
    disabledDate,
  } = props

  const [kmlFile, setKmlFile] = useState<GeoJSONFeatureCollection>({} as GeoJSONFeatureCollection)
  const [openModal, setOpenModal] = useState(false)
  const [fileUpload, setFileUpload] = useState('')
  const [insideList, setInsideList] = useState<any[]>([])
  const [mapContorno, setMapContorno] = useState<google.maps.Map>()
  const [markerOut, setMarkerOut] = useState(false)

  const onUploadContorno = async (file: ChangeEvent<HTMLInputElement>) => {
    const arquivo = file.currentTarget.files.item(0)
    const text = await fileTypeText(arquivo)
    const domKml = parseXml(text)
    const blob = new Blob([text], { type: 'application/xml' })
    const base = await Base64(blob)
    setFileUpload(base)
    const dataGeoJson: GeoJSONFeatureCollection = kml(domKml)
    setKmlFile(dataGeoJson)
  }

  const updateContorno = async (data: google.maps.LatLngLiteral[], inside: any[]) => {
    const kml = geralKmlPolygon(data, form.getFieldValue('cod_talhao'))
    const cripto = btoa(kml)

    const arquivo = `data:@file/xml;base64,${cripto}`

    setInsideList(inside)
    setFileUpload(arquivo)
  }

  const handleChangeDataLiberar = (date: Dayjs) => {
    if (date !== null && date.isAfter(dayjs())) {
      Modal.warning({
        okText: 'Fechar',
        title: 'Essa OS somente será liberada para coleta no aplicativo na data informada',
      })
    }
  }

  const handelClickUpdateContorno = async () => {
    try {
      if (insideList.length > 0) {
        Modal.warning({
          title: `Pontos fora do talhão ${insideList.join(', ')} deseja continuar?`,
          okText: 'Atualizar contorno',
          okButtonProps: { type: 'default' },
          cancelText: 'Cancelar',
          cancelButtonProps: { danger: true, type: 'primary' },
          okCancel: true,
          onOk: async () => {
            const resp = await putPedlaboratorio(update, { arquivo_kml: fileUpload })

            if (resp.status === 200) {
              successAlert('Contorno atualizado com sucesso!');
              setOpenModal(false)
            }
          },
        })
      } else {
        const resp = await putPedlaboratorio(update, { arquivo_kml: fileUpload })
        if (resp.status === 200) {
          successAlert('Contorno atualizado com sucesso!');
          setOpenModal(false)
        }
      }
    } catch (error) {
      tryError(error)
    }
  }

  useEffect(() => {
    if (!openModal && mapContorno) {
      setKmlFile({} as GeoJSONFeatureCollection)
      setFileUpload('')
      setInsideList([])
    }
    if (openModal && mapContorno && kmlFile.features.length > 0) {
      //@ts-ignore
      const position = kmlFile.features[0].geometry.coordinates[0].map((geo) => {
        return {
          lat: Number(geo[1]),
          lng: Number(geo[0]),
        }
      })

      setTimeout(() => {
        const bounds = getBoundsNoArray(position)

        mapContorno.fitBounds(bounds)
      }, 500)
    }
  }, [openModal])

  return (
    <div style={{ height: 'calc(90vh - 240px)', overflow: 'auto' }}>
      <Descriptions
        size='small'
        bordered
        layout='vertical'
        column={1}
        labelStyle={{ backgroundColor: 'red' }}
        contentStyle={{
          fontSize: '80%',
          padding: 8,
          fontWeight: 700,
        }}
      >
        <>
          {dadosCliente?.tipo === 'G' ? (
            <Descriptions.Item>
              <Select
                style={{ width: '100%', marginBottom: 0 }}
                ref={inputRepresentante}
                onChange={(data) => handleChangeRepresentanteLegal(data, null)}
                value={idRepresentanteLegal}
                placeholder='Selecione o Representante Legal'
                size='small'
              >
                {dataRepresentateLegal.map((data) => (
                  <Option key={data.cod_usuario} value={data.cod_usuario}>
                    <div className='capitalized'>{data.usuario_nome.toLowerCase()}</div>
                  </Option>
                ))}
              </Select>
              <br />
            </Descriptions.Item>
          ) : (
            <Descriptions.Item>{dadosCliente?.nome}</Descriptions.Item>
          )}
        </>
      </Descriptions>
      <Descriptions
        column={2}
        bordered
        layout='horizontal'
        size='small'
        labelStyle={{ fontWeight: 700, fontSize: '80%', padding: 8 }}
        contentStyle={{ fontSize: '80%', padding: 8 }}
      >
        <Descriptions.Item label='Endereço'>
          {dadosCliente?.endereco === null || undefined ? '' : dadosCliente?.endereco}
        </Descriptions.Item>
        <Descriptions.Item label='Num.'>
          {dadosCliente?.endereco_numero === null || undefined ? '' : dadosCliente?.endereco_numero}
        </Descriptions.Item>
        <Descriptions.Item label='Cidade'>
          {dadosCliente?.cidade.nome === undefined || null
            ? ' '
            : ` ${dadosCliente?.cidade.nome}/${dadosCliente?.cidade.uf_sigla} `}
        </Descriptions.Item>
        <Descriptions.Item label='CEP'>
          {dadosCliente?.cep === null ? '' : dadosCliente?.cep}
        </Descriptions.Item>
      </Descriptions>
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />

      <Form form={form} name='analise' onFinish={onFinish} layout='vertical'>
        <Col span={24}>
          <Row
            style={{
              pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
            }}
            gutter={[8, 0]}
          >
            <Col flex='1 1 400px'>
              <Form.Item label='Outros solicitantes'>
                <Input
                  readOnly={permissions?.acesso === 1}
                  value={valueSolicitante}
                  onChange={handleOutrosSolicitantes}
                  name='solicitante'
                />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item label='Data da OS' name='data' initialValue={dayjs()}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col flex='1 1 150px'>
              <Form.Item
                help={
                  propriedadesMatricula === ''
                    ? ''
                    : `Matricula: ${propriedadesMatricula}, Área (ha): ${propriedadesHa}`
                }
                label='Propriedade'
                name='cod_propriedade'
                initialValue={null}
              >
                <Select
                  allowClear
                  showSearch
                  onChange={handelChangePropriedade}
                  placeholder='Selecione um propriedade'
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optPropriedades}
                </Select>
              </Form.Item>
            </Col>

            <Col flex='1 1 400px'>
              <UploadFile
                hidden={update <= 0}
                onChange={onUploadContorno}
                className='info-button'
                styles={{ marginTop: '20px', width: '100%' }}
                label={
                  !form.getFieldValue('cod_talhao') ? (
                    <Icons icon={faCircleNotch} spin />
                  ) : (
                    `Atualizar contorno talhão: ${form.getFieldValue('cod_talhao')}`
                  )
                }
              />

              <Form.Item hidden={update > 0} label='Talhão' name='cod_talhao'>
                <Select
                  disabled={disableTalhaoSelect}
                  loading={loadingTalhao}
                  onChange={handleSelectTalhao}
                  showSearch
                  placeholder='Selecione um Talhão'
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optTalhoes}
                </Select>
              </Form.Item>
              <Form.Item hidden name='copiar'>
                <Input type='hidden' />
              </Form.Item>
            </Col>

            <Col flex='1 1 200px'>
              <Form.Item initialValue={null} label='Área de amostra' name='cod_area_amostra'>
                <Select
                  onSelect={handelCalcAreaAmostra}
                  placeholder='Selcione um Valor'
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key='1' value='20'>
                    20
                  </Option>
                  <Option key='2' value='50'>
                    50
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex='1 1 250px'>
              <Form.Item initialValue={null} label='Laboratório' name='cod_laboratorio'>
                <Select
                  showSearch
                  onChange={handelChangeLaboratorio}
                  placeholder='Selecione o laboratório'
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optLaboratorio}
                </Select>
              </Form.Item>
            </Col>
            <Col hidden={localConfig().codCliente === ''} flex='1 1 200px'>
              <Form.Item
                hidden={localConfig().codCliente === ''}
                initialValue={null}
                label='Safra consultoria'
                name='cod_safra_conta'
              >
                <Select
                  showSearch
                  onChange={handleChangeSafraConsultoria}
                  placeholder='Selecione o safra'
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optSafraConsultoria.map((data) => (
                    <Option key={data.cod} value={data.cod}>
                      {data.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col hidden={localConfig().codCliente === ''} flex='1 1 360px'>
              <Form.Item
                hidden={localConfig().codCliente === ''}
                initialValue={null}
                label='Grid amostral'
                name='cod_analise'
              >
                <Select
                  showSearch
                  onChange={handleChangeSafraConsultoria}
                  placeholder='Selecione a analise'
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {lista_cod_analises.map((data) => (
                    <Option key={data.cod} value={data.cod}>
                      {data.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex='1 1 360px'>
              <Form.Item label='Responsáveis coleta' name='cod_responsaveis'>
                <Select
                  allowClear
                  mode='tags'
                  showSearch
                  placeholder='Selecione um responsável'
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {optEquipe}
                </Select>
              </Form.Item>
            </Col>
            <Col flex='1 1 120px'>
              <Form.Item label='Liberar coleta' name='data_liberada'>
                <DatePicker
                  onChange={handleChangeDataLiberar}
                  disabledDate={disabledDate}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col flex='1 1 120px'>
              <Form.Item label='Coletado em' name='data_coleta'>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={24} className='class-remove'>
              <Form.Item name='uploadShape' noStyle>
                <Space wrap>
                  <Button
                    // disabled={disableCampos || talhaSelecionado.length <= 0}
                    onClick={handleAddAnalise}
                    hidden={permissions?.inclusao === 0}
                    type='primary'
                    htmlType='button'
                  >
                    Nova Análise
                  </Button>

                  <Upload
                    ref={uploadShape}
                    accept='.zip'
                    listType='text'
                    onChange={onChangeUpload}
                    maxCount={1}
                    multiple={false}
                    showUploadList={false}
                    customRequest={dummyRequest}
                  >
                    <Button
                      loading={loadingShape}
                      hidden={permissions?.inclusao === 0}
                      className='info-button'
                      type='primary'
                      htmlType='button'
                      icon={<UploadOutlined />}
                    >
                      Importar Malha Geral
                    </Button>
                  </Upload>

                  {malha?.features?.length <= 0 || malha?.features?.length === undefined ? null : (
                    <Button
                      style={{ pointerEvents: 'auto' }}
                      loading={isModalVisibleMap}
                      className='info-button'
                      type='primary'
                      onClick={handleOkMap}
                      htmlType='button'
                      icon={<EyeOutlined />}
                    >
                      {`${malha?.features?.length} pontos | Visualizar Malha Geral`}
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Form.Item hidden initialValue={1} name='status' />
      </Form>
      <Modal
        style={{ minWidth: '90%', marginTop: '-100px' }}
        cancelButtonProps={{
          onClick: () => {
            setOpenModal(false)
          },
        }}
        closeIcon={false}
        okButtonProps={{ onClick: () => handelClickUpdateContorno(), disabled: markerOut }}
        okText='Atualizar contorno'
        forceRender
        open={openModal}
      >
        <MapaUpdateContorno
          kml={kmlFile}
          outMarker={setMarkerOut}
          status={setOpenModal}
          malha={malha}
          updatePolygon={updateContorno}
          mapContorno={setMapContorno}
        />
      </Modal>
    </div>
  )
}

export default DataClienteOrder
