import { Drawer, message } from 'antd'
import React, { useState } from 'react'
import { NavegacaoDrawer } from '../../../recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faFileCirclePlus,
  faPaperPlane,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { ItensObservacao, useCadastrarObs } from '../../../../../services/Propriedades'
import { HeaderDrawer } from '../../../../../Components/CustomUi/headerGaveta'
import { CardsObservacao } from './components/cardsObservacao'
import TextArea from '../../../../../Components/CustomUi/textarea'
import { CampoAddObs } from './styled'
import dayjs from 'dayjs'
import { Render } from '../../../../../Components/CustomUi/render'
import { UploadImagem } from '../../../../../Components/CustomUi/uploadImagem'
import { TitleH2 } from '../../../../../Components/CustomUi/titleH2'

interface PropsGavetaPontos {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  fecharGaveta?: Function
  pontosObs: any[]
  loading: boolean
  codObs: number
}

export const GavetaPontoObs: React.FC<PropsGavetaPontos> = ({
  open,
  fecharGaveta,
  setOpen,
  pontosObs,
  loading,
  codObs,
}) => {
  const [mensagem, setMensagem] = useState('')
  const [arquivoImg, setArquivoImg] = useState([])
  const [arquivoNome, setArquivoNome] = useState('')
  const { carregando_cadastrar_obs, mudateCadastrarObs } = useCadastrarObs()

  // const observacao = [
  //   {
  //     mensagem: mensagem,
  //     data_hora: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  //     arquivo: arquivoNome,
  //   },
  // ]

  // function verificar() {
  //   if (!mensagem) {
  //     return message.error({ content: 'Preencha o campo de mensagem' })
  //   } else {
  //     mudateCadastrarObs({
  //       cod: codObs,
  //       observacoes: observacao,
  //       arquivos: arquivoImg,
  //       limparCamposObs: () => limparCamposObs(),
  //     })
  //   }
  // }

  // function limparCamposObs() {
  //   setMensagem('')
  //   setArquivoImg([])
  //   setArquivoNome('')
  // }

  return (
    <Drawer
      width={350}
      open={open}
      onClose={() => setOpen(false)}
      closable={false}
      bodyStyle={{ padding: 0 }}
      headerStyle={{ padding: '10px 10px' }}
      destroyOnClose
      mask={false}
      title={
        <NavegacaoDrawer>
          <ButtonCustom
            danger
            icon={<FontAwesomeIcon icon={faXmark} />}
            type='tertiary'
            onClick={() => setOpen(false)}
            style={{ marginLeft: -10 }}
          >
            Fechar
          </ButtonCustom>
        </NavegacaoDrawer>
      }
    >
      <HeaderDrawer>
        <TitleH2>{pontosObs[0]?.nome_ponto}</TitleH2>
      </HeaderDrawer>
      <CardsObservacao loading={loading} observacao={pontosObs} />
    </Drawer>
  )
}
