import React, { useEffect, useRef, useState } from 'react'
import { Upload } from 'antd/lib'
import { v4 as uid } from 'uuid'
import { PlusOutlined } from '@ant-design/icons'
import { AxiosResponse } from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UploadChangeParam } from 'antd/es/upload'
import { Drawer, Modal, Spin } from 'antd'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'

//ASSETS
import Pdf from '../../../assets/img/pdf.png'

//STYLES
import { Container } from './styled'
import { NavegacaoDrawer } from '../recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'

//APP
import { getCatPatrimonio } from '../../../services/config/categoriasPatrimonio'
import { getCategoriaMarca } from '../../../services/config/marca'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { getEquipe, TypesEquipe } from '../../../services/Equipe'
import { limpar, verficarDadosEditarApi } from './meta'
import { mascaraOdom, removeMaskValor, tirarAdicionarPonto } from '../../../services/Afins'
import {
  delPatrimonio,
  getFilePatrimonioArr,
  getFilePatrimonioBlob,
  getPatrimonio,
  pathPatrimonio,
  postPatrimonio,
  putPatrimonio,
} from '../../../services/Patrimonio'
import { verificarSeAlgoEstaPreenchido, verificarSeHouveAlteracoes } from './meta/novo-index'

//COMPONENTS
import ViewPage from '../../../Components/ViewPage/'
import Carregando from '../../../Components/CustomUi/loading'
import gerarBase64 from '../../../Components/Base64Geral'
import ButtonCustom from '../../../Components/ButtonCustom'
import { Render } from '../../../Components/CustomUi/render'
import { TitleH1 } from '../../../Components/CustomUi/titleH1'
import { TabelaPatrimonios } from './components/tabela'
import { GavetaPatrimonios } from './components/gavetaPatrimonio'
import { ConfirmarFecharGaveta } from './components/confirmarFecharGaveta'
import { destroyAlert, errorAlert, loadingAlert } from '../../../utils/alert'

//TYPES
export type TypeListaEngrenagem = {
  cod: number
  posicao: number
  tipo: number
  categoria: number
  quantidade_dentes: number
  nome_posicao: string
  nome_tipo: 'Motriz' | 'Movida'
  nome_categoria: 'Semente' | 'Fertilizante' | string
  is_new?: boolean
}

const PageNovoPatrimonio: React.FC = () => {
  //STATE
  const [dataTabela, setDataTabela] = useState([])
  const [dataTabelaFiltrar, setDataTabelaFiltrar] = useState([])
  const [dados_campos_originais, setDados_campos_originais] = useState({})
  const [update, setUpdate] = useState(0)
  const [cod_patrimonio, setCod_patrimonio] = useState<number>(null)
  const [loadingTabela, setLoadingTabela] = useState(false)
  const [open, setOpen] = useState(false)
  const [dataCategoria, setDataCategoria] = useState([])
  const [dataResponsaveis, setDataResponsaveis] = useState([])
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [openPopover, setOpenPopover] = useState(false)
  const [duplicar, setDuplicar] = useState(false)
  const [selectedFile, setSelectedFile] = useState([])
  const [arquivos, setArquivos] = useState([])
  const [idFileList, setIdFileList] = useState([])
  const [dataMarca, setDataMarca] = useState([])
  const [dataTipoCategorias, setTipoDataCategorias] = useState([])
  const [dadosOriginais, setDadosOriginais] = useState({})
  const [preview, setPreview] = useState(null)
  const [previewImage, setPreviewImage] = useState({
    image: null,
    visible: false,
    title: null,
  })
  const [loadingDowloandingFiles, setLoadingDowloandingFiles] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingInfos, setLoadingInfos] = useState(true)
  const [loadingEngrenagensEntrada, setLoadingEngrenagensEntrada] = useState(false)
  const [loadingEngrenagensSaida, setLoadingEngrenagensSaida] = useState(false)
  const [loadingEngrenagensRecambio, setLoadingEngrenagensRecambio] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [inativos, setInavitos] = useState(false)

  //FORM
  const [nome, setNome] = useState('')
  const [modelo, setModelo] = useState('')
  const [chassi, setChassi] = useState('')
  const [serie, setSerie] = useState('')
  const [frota, setFrota] = useState('')
  const [dataAquisição, setDataAquisição] = useState('')
  const [valor, setValor] = useState('')
  const [anoModelo, setAnoModelo] = useState('')
  const [anoFabricação, setAnoFabricação] = useState('')
  const [obs, setObs] = useState('')
  const [odom_horimetro, setOdomHorimetro] = useState('')
  const [placa, setPlaca] = useState('')
  const [crv, setCrv] = useState('')
  const [cla, setCla] = useState('')
  const [renavam, setRenavam] = useState('')
  const [engrenagem, setEngrenagem] = useState([])
  const [formDataEntrada, setFormDataEntrada] = useState([])
  const [formDataSaida, setFormDataSaida] = useState([])
  const [formDataRecambio, setFormDataRecambio] = useState([])
  const [status, setStatus] = useState(null)
  const [condicao, setCondicao] = useState(null)
  const [numeracao_motor, setNumeracao_motor] = useState<string>(null)
  const [cor_predominante, setCor_predominante] = useState('')
  const [numero_linhas, setNumero_linhas] = useState('')
  const [espacamento_linhas, setEspacamento_linhas] = useState('')
  const [lista_engrenagens, setLista_engrenagens] = useState<TypeListaEngrenagem[]>([])
  const [tab_selecionada_atual, setTab_selecionada_atual] = useState<string>('Categoria')
  //RESPONSÁVEL DA FROTA
  const [responsavelFrota, setResponsavelFrota] = useState('')
  const [responsavelFrotaId, setResponsavelFrotaId] = useState(null)
  //CATEGORIA
  const [categoria, setCategoria] = useState('')
  const [categoriaId, setCategoriaId] = useState(null)
  //TIPO CATEGORIA
  const [tipoCategoria, setTipoCategoria] = useState('')
  const [tipoCategoriaId, setTipoCategoriaId] = useState(null)
  //MARCA
  const [marca, setMarca] = useState('')
  const [marcaId, setMarcaId] = useState(null)
  //MOTORIZADO
  const [tab_motorizado, setTab_motorizado] = useState(false)
  //TIPO MEDIDOR
  const [tipoMedidor, setTipoMedidor] = useState('')
  const [tipoMedidorId, setTipoMedidorId] = useState(null)
  const tipoOdom = [
    { label: 'Nenhum', value: 0 },
    { label: 'Odômetro', value: 1 },
    { label: 'Horímetro', value: 2 },
  ]
  //TIPO ORIGEM
  const [tipoOrigem, setTipoOrigem] = useState('')
  const [tipoOrigemId, setTipoOrigemId] = useState(null)
  const [proprietario_origem, setProprietarioOrigem] = useState('')
  //TIPO COMBUSTIVEL
  const [tipoCombustivel, setTipoCombustivel] = useState('')
  const [tipoCombustivelId, setTipoCombustivelId] = useState(null)
  //AVIÃO
  const [capacidade_tanque_calda, setCapacidade_tanque_calda] = useState('')
  //IMPLEMENTO GRADE
  const [numero_discos, setNumero_discos] = useState('')
  const [diametro_discos, setDiametro_discos] = useState('')

  const [validarEngrenagem, setValidarEngrenagem] = useState<boolean>(false)

  const uploadImagens = useRef(null)

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Arquivos</div>
    </div>
  )

  const dowLoadButton = (
    <div>
      <Spin />
      <div style={{ marginTop: 8 }}>Carregando...</div>
    </div>
  )

  const listPatrimonio = async () => {
    setLoadingTabela(true)
    const response = await getPatrimonio({ pagination: pagination, page: page })
    const dados = response.data.itens

    dados.forEach((data) => {
      data.loading = false
    })

    setDataTabela(dados)
    setDataTabelaFiltrar(dados)
    setTotal(response.data.total)
    setLoadingTabela(false)
  }

  async function listarEquipe() {
    const response: AxiosResponse<TypesEquipe> = await getEquipe({
      pagination: -1,
      status: 1,
    })

    setDataResponsaveis(response.data.itens)
  }

  async function deletarPatrimonio(cod) {
    const resp = await delPatrimonio(cod, listPatrimonio)
  }

  const listMarcas = async (data) => {
    const response = await getCategoriaMarca('', '', data, 'nome')

    setDataMarca(response.data.itens)
  }

  const handleChangeCategoria = async (data) => {
    setLoading(true)
    listMarcas(data)
    if (data !== null && data !== undefined) {
      const response = await getCatPatrimonio({
        codCategoria: data,
        order: 'nome',
        pagination: -1,
      })

      setTipoDataCategorias(response.data.itens)
      setLoading(false)
    } else {
      setTipoDataCategorias([])
    }
  }

  function atualizar(tipoPosicao) {
    editPatrimonio(update, tipoPosicao)
  }

  const onChangeUpload = (info: UploadChangeParam) => {
    info.file.status = 'done'
    if (info.file.status === 'done') {
      const files = info.fileList

      files.forEach(async (data) => {
        if (data.type === 'application/pdf') {
          data.thumbUrl = Pdf
          // @ts-ignore
          data.preview = data.url !== undefined ? '' : await gerarBase64(data.originFileObj)
        }
      })

      setSelectedFile(files)
    }
  }

  const onRemoveFile = (data) => {
    const dados = selectedFile
    const ids = idFileList

    ids.push(data.cod)

    setSelectedFile(dados.filter((remove) => remove.uid !== data.uid))
    setIdFileList(ids)
  }

  const handlePreviewImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await gerarBase64(file.originFileObj)
    }

    setPreviewImage({
      image: file.url || file.preview,
      visible: true,
      title: '',
    })
    if (file.type === 'application/pdf') {
      setPreview(file.url || file.preview)
    } else {
      setPreview(file.url || file.preview)
    }
    setOpenPreview(true)
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  function removerUltimosDigitos(value) {
    if (!value) return ''
    // Remove qualquer caractere que não seja número
    const numericValue = value.replace(/\D/g, '')
    // Retorna o valor sem os dois últimos dígitos
    return numericValue.slice(0, -2)
  }

  const editPatrimonio = async (data, tipoPosicao) => {
    const response = await getPatrimonio({ cod: data, pagination: -1 })
    const dados = response.data.itens[0]

    // console.log("dados: ", dados)
    setDados_campos_originais(dados)

    //@ts-ignore
    setLista_engrenagens(dados?.engrenagem ?? [])

    setNome(dados?.nome)
    setModelo(dados?.modelo)
    setObs(dados?.descricao)
    setTipoCategoriaId(dados?.cod_tipo)
    setTipoCategoria(dados?.tipo_nome)
    setCategoriaId(
      dados?.tipo?.cod_categoria === null ? dados?.tipo?.cod : dados?.tipo?.cod_categoria
    )
    setMarca(dados?.marca_nome)
    setMarcaId(dados?.cod_marca)
    setCondicao(dados?.condicao_aquisicao === 1 ? '1' : '2')
    setValor(dados?.valor)
    setCrv(dados?.numeracao_crv)
    setCla(dados?.numeracao_cla)
    setOdomHorimetro(removerUltimosDigitos(dados?.odom_horimetro))
    setDataAquisição(dados?.data_aquisicao)
    setPlaca(dados?.placa)
    setRenavam(dados?.renavam)
    setAnoFabricação(dados?.ano)
    setTab_motorizado(dados?.motorizado === 1 ? true : false)
    setTipoMedidorId(dados?.tipo_odom)
    const tipoOdometro = tipoOdom.filter((item) => item.value === dados?.tipo_odom)
    setTipoMedidor(tipoOdometro[0].label)
    setFrota(dados?.frota)
    setChassi(dados?.chassi)
    setSerie(dados?.serie)
    setAnoModelo(dados?.ano_modelo)
    setResponsavelFrotaId(dados?.cod_responsavel)
    setResponsavelFrota(dados?.responsavel?.[0]?.nome)
    setTipoCombustivel(dados?.tipo_combustivel)
    setEngrenagem(dados?.engrenagem)
    setTipoOrigem(dados?.origem_patrimonio)
    setProprietarioOrigem(dados?.proprietario_origem)
    setNumeracao_motor(dados?.numeracao_motor)
    setNumero_linhas(dados?.numero_linhas)
    setEspacamento_linhas(dados?.espacamento_linhas)
    setCapacidade_tanque_calda(dados?.capacidade_tanque_calda)
    setNumero_discos(dados?.numero_discos)
    setDiametro_discos(dados?.diametro_discos)
    setCor_predominante(dados?.cor_predominante)
    setStatus(dados?.status)
    if (dados?.engrenagem !== undefined) {
      if (tipoPosicao === 1 || tipoPosicao === '') {
        setLoadingEngrenagensEntrada(true)
        const entrada = dados?.engrenagem.filter(
          (item) => item.nome_posicao === 'Lateral de Entrada'
        )

        setFormDataEntrada(entrada)
      }

      if (tipoPosicao === 2 || tipoPosicao === '') {
        setLoadingEngrenagensSaida(true)
        const saidaSemente = dados?.engrenagem.filter(
          (item) => item.nome_posicao === 'Lateral de Saída(Semente)'
        )
        const saidaFertilizante = dados?.engrenagem.filter(
          (item) => item.nome_posicao === 'Lateral de Saída(Fertilizante)'
        )

        setFormDataSaida([...saidaSemente, ...saidaFertilizante])
      }

      if (tipoPosicao === 3 || tipoPosicao === '') {
        setLoadingEngrenagensRecambio(true)
        const recambio = dados?.engrenagem.filter((item) => item.nome_posicao === 'Recâmbio')

        setFormDataRecambio(recambio)
      }
    }

    const dadosEditar = {
      nome: dados?.nome,
      modelo: dados?.modelo,
      descricao: dados?.descricao,
      cod_tipo: dados?.cod_tipo,
      cod_marca: dados?.cod_marca,
      cod_categoria:
        dados?.tipo?.cod_categoria === null ? dados?.tipo?.cod : dados?.tipo?.cod_categoria,
      status: dados?.status,
      condicao_aquisicao: dados?.condicao_aquisicao === 1 ? '1' : '2',
      valor: dados?.valor,
      numeracao_crv: dados?.numeracao_crv,
      numeracao_cla: dados?.numeracao_cla,
      km_inicial: '',
      odom_horimetro: mascaraOdom(removerUltimosDigitos(dados?.odom_horimetro)),
      data_aquisicao: dados?.data_aquisicao,
      placa: dados?.placa,
      renavam: dados?.renavam,
      ano: dados?.ano,
      motorizado: dados?.motorizado === 1 ? 1 : 0,
      tipo_odom: dados?.tipo_odom,
      frota: dados?.frota,
      chassi: dados?.chassi,
      serie: dados?.serie,
      ano_modelo: dados?.ano_modelo,
      cod_responsavel: dados?.cod_responsavel,
      tipo_combustivel: dados?.tipo_combustivel,
      engrenagem: dados?.engrenagem,
      origem_patrimonio: dados?.origem_patrimonio,
      proprietario_origem: dados?.proprietario_origem,
      numeracao_motor: dados?.numeracao_motor,
      numero_linhas: dados?.numero_linhas,
      espacamento_linhas: dados?.espacamento_linhas,
      capacidade_tanque_calda: dados?.capacidade_tanque_calda,
      numero_discos: dados?.numero_discos,
      diametro_discos: dados?.diametro_discos,
    }

    // console.log('engrenagem',dados?.engrenagem)
    setDadosOriginais(dadosEditar)

    if (dados.arquivos.length > 0) {
      const array = dados.arquivos

      setLoadingDowloandingFiles(true)
      let fileList: any = []
      let arquivos: any

      for (let index = 0; index < array.length; index += 1) {
        const files = array[index]

        const uuid = uid()

        const extencao = dados.arquivos[index].nome.split('.')[1]

        const typeFile = `${extencao === 'pdf' ? 'application' : 'image'}/${extencao}`

        let exibir

        if (extencao === 'pdf') {
          // eslint-disable-next-line no-await-in-loop
          const retorno = await getFilePatrimonioBlob(files.arquivo_url)

          exibir = {
            // @ts-ignore
            url: URL.createObjectURL(retorno.data),
            thumbUrl: Pdf,
          }
        } else {
          // eslint-disable-next-line no-await-in-loop
          const retorno = await getFilePatrimonioArr(files.arquivo_url)
          const base64String = Buffer.from(retorno.data).toString('base64')

          exibir = {
            // @ts-ignore
            preview: `data:image/${extencao};base64,${base64String}`,
            thumbUrl: `data:image/${extencao};base64,${base64String}`,
          }
        }

        const file = {
          uid: uuid,
          cod: files.cod,
          status: 'done',
          response: 'done',
          name: dados.arquivos[index].nome,
          url: exibir.url,
          preview: exibir.preview,
          thumbUrl: exibir.thumbUrl,
          type: typeFile,
        }

        const temp = {
          uid: uuid,
          cod: files.cod,
          status: 'done',
          response: 'done',
          name: dados.arquivos[index].nome,
          url: exibir.url,
          preview: exibir.preview,
          thumbUrl: exibir.thumbUrl,
          type: typeFile,
        }

        fileList = [...fileList, temp]

        arquivos = {
          file,
          fileList,
        }

        onChangeUpload(arquivos)
      }
      setLoadingDowloandingFiles(false)
    }
    setLoadingEngrenagensEntrada(false)
    setLoadingEngrenagensSaida(false)
    setLoadingEngrenagensRecambio(false)
    // destroyAlert();
    setLoadingInfos(false)
  }

  const salvarEditarPatrimonio = async () => {
    const arrayFiles = selectedFile.map((item) => item.originFileObj)

    setArquivos(arrayFiles)
    setLoadingButton(true)

    let remover_cod = lista_engrenagens.map((item) => {
      if (item.is_new === false || item.is_new === undefined || item.is_new === null) {
        return { ...item }
      }
      return {
        posicao: item.posicao,
        tipo: item.tipo,
        categoria: item.categoria,
        quantidade_dentes: item.quantidade_dentes,
      }
    })

    let dataFinal = {
      nome: nome,
      modelo: modelo,
      descricao: obs,
      cod_tipo: tipoCategoriaId === null ? categoriaId : tipoCategoriaId,
      cod_marca: marcaId,
      status: 1,
      condicao_aquisicao: condicao === '1' ? 1 : 2,
      numeracao_motor: numeracao_motor,
      valor: tirarAdicionarPonto(valor),
      km_inicial: '',
      odom_horimetro: removeMaskValor(odom_horimetro),
      cor_predominante: cor_predominante,
      numero_linhas: numero_linhas,
      espacamento_linhas: espacamento_linhas,
      capacidade_tanque_calda: capacidade_tanque_calda,
      numero_discos: numero_discos,
      diametro_discos: diametro_discos,
      data_aquisicao: dataAquisição,
      placa: placa,
      renavam: renavam,
      ano: anoFabricação,
      numeracao_crv: crv,
      numeracao_cla: cla,
      motorizado: tab_motorizado === false ? 0 : 1,
      tipo_odom: tipoMedidorId,
      frota: frota,
      chassi: chassi,
      serie: serie,
      ano_modelo: anoModelo,
      arquivo: arrayFiles,
      cod_responsavel: responsavelFrotaId,
      tipo_combustivel: tipoCombustivelId,
      engrenagem: remover_cod,
      origem_patrimonio: tipoOrigemId,
      proprietario_origem: proprietario_origem,
    }

    if (cod_patrimonio <= 0 || duplicar) {
      if (!numero_linhas) {
        errorAlert('Preencha o campo número de linhas!', 7000, 'linhas')
        setLoadingButton(false)
        return null
      }

      if (!espacamento_linhas) {
        errorAlert('Preencha o campo espaçamento das linhas!', 7000, 'espacamento')
        setLoadingButton(false)
        return null
      }
      const response = await postPatrimonio(dataFinal, onClose, setLoadingButton)
    } else {
      const response = await putPatrimonio(
        update,
        verficarDadosEditarApi(
          update,
          nome,
          modelo,
          obs,
          tipoCategoriaId,
          categoriaId,
          marcaId,
          condicao,
          numeracao_motor,
          numero_linhas,
          espacamento_linhas,
          capacidade_tanque_calda,
          numero_discos,
          diametro_discos,
          cor_predominante,
          valor,
          odom_horimetro,
          dataAquisição,
          placa,
          renavam,
          anoFabricação,
          anoModelo,
          crv,
          cla,
          tipoMedidorId,
          frota,
          tab_motorizado === false ? 0 : 1,
          chassi,
          serie,
          responsavelFrotaId,
          tipoCombustivelId,
          tipoCombustivel,
          tipoOrigem,
          tipoOrigemId,
          proprietario_origem,
          lista_engrenagens,
          dadosOriginais
        ),
        '',
        atualizar,
        ''
      )
      
      setLoadingButton(false)
    }
  }

  const handleAlteraStatus = async (status, cod) => {
    loadingAlert({ msg: 'Alterando status...', id: 'status' })
    const novoStatus = !status

    const response = await pathPatrimonio(cod, {
      status: novoStatus === false ? 0 : 1,
    })
    const novosDados = dataTabela.map((item) =>
      item.cod === cod ? { ...item, status: novoStatus } : item
    )

    setDataTabelaFiltrar(novosDados)

    destroyAlert('status')
  }

  function abrirGaveta() {
    setLista_engrenagens([])
    setSelectedFile([])
    setCod_patrimonio(null)
    setArquivos([])
    setUpdate(0)
    setOpen(true)
    setLoadingInfos(false)
  }

  function abrirGavetaEditar(cod, duplicar) {
    setDuplicar(duplicar)
    setOpen(true)
    setUpdate(cod)
    setCod_patrimonio(cod)
  }

  function validarAlteracoes() {
    let campos_formulario = {
      categoria: categoriaId,
      tipo_categoria: tipoCategoriaId,
      marca: marcaId,
      condicao_aquisicao: condicao,
      tipo_origem: tipoOrigem,
      proprietario_origem: proprietario_origem,
      tipo_motorizado: tab_motorizado,
      engrenagens: lista_engrenagens,
      tipo_medidor: tipoMedidorId,
      hodometro: mascaraOdom(odom_horimetro),
      tipo_combustivel: tipoCombustivel,
      numeracao_do_motor: numeracao_motor,
      cor_predominante: cor_predominante,
      numero_linhas: numero_linhas,
      espacamento_linhas: espacamento_linhas,
      capacidade_tanque_calda: capacidade_tanque_calda,
      numero_discos: numero_discos,
      diametro_discos: diametro_discos,
      nome: nome,
      modelo: modelo,
      chassi: chassi,
      placa: placa,
      renavam: renavam,
      crv: crv,
      cla: cla,
      serie: serie,
      frota: frota,
      responsavel_pela_frota: responsavelFrotaId,
      dataAquisicao: dataAquisição,
      valor: valor,
      ano_fabricacao: anoFabricação,
      ano_modelo: anoModelo,
      observacao: obs,
    }

    verificarSeHouveAlteracoes(dados_campos_originais, campos_formulario, setOpenPopover, onClose)
  }
  function validarNovoCadastro() {
    let campos_formulario = {
      categoria: categoriaId,
      tipo_categoria: tipoCategoriaId,
      marca: marcaId,
      condicao_aquisicao: condicao,
      tipo_origem: tipoOrigem,
      proprietario_origem: proprietario_origem,
      tipo_motorizado: tab_motorizado,
      engrenagens: lista_engrenagens,
      tipo_medidor: tipoMedidorId,
      hodometro: mascaraOdom(odom_horimetro),
      tipo_combustivel: tipoCombustivel,
      numeracao_do_motor: numeracao_motor,
      cor_predominante: cor_predominante,
      numero_linhas: numero_linhas,
      espacamento_linhas: espacamento_linhas,
      capacidade_tanque_calda: capacidade_tanque_calda,
      numero_discos: numero_discos,
      diametro_discos: diametro_discos,
      nome: nome,
      modelo: modelo,
      chassi: chassi,
      placa: placa,
      renavam: renavam,
      crv: crv,
      cla: cla,
      serie: serie,
      frota: frota,
      responsavel_pela_frota: responsavelFrotaId,
      dataAquisicao: dataAquisição,
      valor: valor,
      ano_fabricacao: anoFabricação,
      ano_modelo: anoModelo,
      observacao: obs,
    }

    verificarSeAlgoEstaPreenchido(campos_formulario, setOpenPopover, onClose)
  }

  function onClose() {
    setOpenPopover(false)
    setOpen(false)
    setLoadingInfos(true)
    setLoadingButton(false)
    limpar(
      setNome,
      setModelo,
      setChassi,
      setSerie,
      setFrota,
      setDataAquisição,
      setValor,
      setAnoModelo,
      setAnoFabricação,
      setObs,
      setOdomHorimetro,
      setPlaca,
      setRenavam,
      setEngrenagem,
      setFormDataEntrada,
      setFormDataSaida,
      setFormDataRecambio,
      setStatus,
      setCrv,
      setCla,
      setCondicao,
      setNumeracao_motor,
      setCor_predominante,
      setTab_selecionada_atual,
      setResponsavelFrota,
      setResponsavelFrotaId,
      setCategoria,
      setCategoriaId,
      setTipoCategoria,
      setTipoCategoriaId,
      setMarca,
      setMarcaId,
      setTab_motorizado,
      setTipoMedidor,
      setTipoMedidorId,
      setTipoOrigem,
      setTipoOrigemId,
      setProprietarioOrigem,
      setTipoCombustivel,
      setTipoCombustivelId,
      setDataCategoria,
      setDataMarca,
      setTipoDataCategorias,
      setNumero_linhas,
      setEspacamento_linhas,
      setCapacidade_tanque_calda,
      setNumero_discos,
      setDiametro_discos
    )
    listPatrimonio()
    listCategorias()
    setDuplicar(false)
    setUpdate(0)
    setCod_patrimonio(null)
  }

  const listCategorias = async () => {
    const response = await getCatPatrimonio({
      principal: 1,
      order: 'nome',
      pagination: -1,
    })
    setDataCategoria(response.data.itens)
  }

  useEffect(() => {
    listPatrimonio()
    listCategorias()
    listarEquipe()
  }, [])

  useEffect(() => {
    listPatrimonio()
  }, [page])

  useEffect(() => {
    if (update > 0) {
      editPatrimonio(update, '')
    }
  }, [update])

  useEffect(() => {
    if (categoriaId > 0) {
      handleChangeCategoria(categoriaId)
    }
  }, [categoriaId])

  useEffect(() => {
    if (update > 0 && categoriaId > 0) {
      handleChangeCategoria(categoriaId)
      const filterCategoria = dataCategoria.filter((item) => item.cod === categoriaId)
      setCategoria(filterCategoria[0].nome)
    }
  }, [update, categoriaId])

  useEffect(() => {
    if (inativos) {
      setLoadingTabela(true)
      const filtrar = dataTabela.filter((item) => item.status === 1)
      setDataTabelaFiltrar(filtrar)
      setTimeout(() => {
        setLoadingTabela(false)
      }, 500)
    } else {
      listPatrimonio()
    }
  }, [inativos])

  useEffect(() => {
    if (categoriaId !== 27 || categoriaId !== 2) {
      setTab_motorizado(false)
    }
  }, [categoriaId])

  return (
    <Container>
      <ViewPage
        title='Patrimônios'
        btnClick={null}
        newHide
        rotina={27}
        onPermission={setPermissions}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Patrimônios</TitleH1>
        </div>

        <TabelaPatrimonios
          list_patrimonios={dataTabelaFiltrar}
          loading={loadingTabela}
          handleAlteraStatus={handleAlteraStatus}
          deletarPatrimonio={deletarPatrimonio}
          abrir={abrirGaveta}
          setPagination={setPagination}
          editar={abrirGavetaEditar}
          page={page}
          setPage={setPage}
          total={total}
          inativos={inativos}
          setInativos={setInavitos}
        />

        <Drawer
          width={880}
          open={open}
          onClose={update <= 0 ? () => validarNovoCadastro() : () => validarAlteracoes()}
          closable={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '10px 10px' }}
          destroyOnClose
          footer={
            <div className='footer'>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                type='primary'
                onClick={salvarEditarPatrimonio}
                loading={loadingButton}
                disabled={loadingButton || validarEngrenagem}
              >
                Salvar
              </ButtonCustom>
            </div>
          }
          title={
            <NavegacaoDrawer>
              <ButtonCustom
                danger
                icon={<FontAwesomeIcon icon={faXmark} />}
                type='tertiary'
                onClick={update <= 0 ? () => validarNovoCadastro() : () => validarAlteracoes()}
                style={{ marginLeft: -10 }}
              >
                Fechar
              </ButtonCustom>
            </NavegacaoDrawer>
          }
        >
          <ConfirmarFecharGaveta
            title={`Você não salvou o cadastro!`}
            message='Deseja fechar sem salvar? Todas as informações inseridas serão perdidas.'
            openPopover={openPopover}
            setOpenPopover={setOpenPopover}
            onClose={onClose}
          />

          <Render condition={loadingInfos}>
            <Carregando
              height={'100%'}
              animation
              justifyContent='center'
              legenda='Carregando detalhes do patrimônio...'
            />
          </Render>

          <Render condition={!loadingInfos}>
            <GavetaPatrimonios
              lista_engrenagens={lista_engrenagens}
              setLista_engrenagens={setLista_engrenagens}
              responsaveis={dataResponsaveis}
              categorias={dataCategoria}
              update={update}
              anoFabricacao={anoFabricação}
              anoModelo={anoModelo}
              chassi={chassi}
              cla={cla}
              crv={crv}
              dataAquisicao={dataAquisição}
              formDataEntrada={formDataEntrada}
              formDataRecambio={formDataRecambio}
              formDataSaida={formDataSaida}
              frota={frota}
              handleChangeCategoria={handleChangeCategoria}
              modelo={modelo}
              nome={nome}
              obs={obs}
              placa={placa}
              renavam={renavam}
              responsavelFrota={responsavelFrota}
              responsavelFrotaId={responsavelFrotaId}
              setFormDataEntrada={setFormDataEntrada}
              setFormDataRecambio={setFormDataRecambio}
              setFormDataSaida={setFormDataSaida}
              setResponsavelFrota={setResponsavelFrota}
              serie={serie}
              setAnoFabricacao={setAnoFabricação}
              setAnoModelo={setAnoModelo}
              setChassi={setChassi}
              setCla={setCla}
              setCrv={setCrv}
              setDataAquisicao={setDataAquisição}
              setFrota={setFrota}
              setModelo={setModelo}
              setNome={setNome}
              setObs={setObs}
              setPlaca={setPlaca}
              setRenavam={setRenavam}
              setResponsavelFrotaId={setResponsavelFrotaId}
              setSerie={setSerie}
              setTipoCombustivel={setTipoCombustivel}
              setTipoCombustivelId={setTipoCombustivelId}
              setValor={setValor}
              tipoCombustivel={tipoCombustivel}
              tipoCombustivelId={tipoCombustivelId}
              valor={valor}
              categoria={categoria}
              categoriaId={categoriaId}
              condicao={condicao}
              dataMarca={dataMarca}
              dataTipoCategorias={dataTipoCategorias}
              engrenagem={engrenagem}
              marca={marca}
              marcaId={marcaId}
              odom_horimetro={odom_horimetro}
              proprietarioOrigem={proprietario_origem}
              setCategoria={setCategoria}
              setCategoriaId={setCategoriaId}
              loading={loading}
              setCondicao={setCondicao}
              setMarca={setMarca}
              setMarcaId={setMarcaId}
              setOdomHorimetro={setOdomHorimetro}
              setProprietarioOrigem={setProprietarioOrigem}
              setTab_motorizado={setTab_motorizado}
              setTipoCategoria={setTipoCategoria}
              setTipoCategoriaId={setTipoCategoriaId}
              setTipoMedidor={setTipoMedidor}
              setTipoMedidorId={setTipoMedidorId}
              setTipoOrigem={setTipoOrigem}
              setTipoOrigemId={setTipoOrigemId}
              setTab_selecionada_atual={setTab_selecionada_atual}
              tab_motorizado={tab_motorizado}
              tab_selecionada_atual={tab_selecionada_atual}
              tipoCategoria={tipoCategoria}
              tipoCategoriaId={tipoCategoriaId}
              status={status}
              tipoMedidor={tipoMedidor}
              tipoMedidorId={tipoMedidorId}
              tipoOdom={tipoOdom}
              tipoOrigem={tipoOrigem}
              tipoOrigemId={tipoOrigemId}
              loadingEngrenagensEntrada={loadingEngrenagensEntrada}
              loadingEngrenagensSaida={loadingEngrenagensSaida}
              loadingEngrenagensRecambio={loadingEngrenagensRecambio}
              atualizar={atualizar}
              duplicar={duplicar}
              cod_patrimonio={cod_patrimonio}
              numeracao_motor={numeracao_motor}
              setNumeracao_motor={setNumeracao_motor}
              setValidarEngrenagem={setValidarEngrenagem}
              cor_predominante={cor_predominante}
              setCor_predominante={setCor_predominante}
              numeroDeLinhas={numero_linhas}
              setNumeroDeLinhas={setNumero_linhas}
              espaçamentoDasLinhas={espacamento_linhas}
              setEspaçamentosDasLinhas={setEspacamento_linhas}
              capacidade_tanque_calda={capacidade_tanque_calda}
              setCapacidade_tanque_calda={setCapacidade_tanque_calda}
              numero_discos={numero_discos}
              setNumero_discos={setNumero_discos}
              diametro_discos={diametro_discos}
              setDiametro_discos={setDiametro_discos}
            />
            {tab_selecionada_atual === 'Informações do patrimônio' && (
              <>
                <div style={{ width: '100%', paddingLeft: '15px' }}>
                  <Upload
                    ref={uploadImagens}
                    accept='image/jpeg, image/jpg ,image/png, application/pdf'
                    listType='picture-card'
                    onChange={onChangeUpload}
                    fileList={selectedFile}
                    customRequest={dummyRequest}
                    onRemove={onRemoveFile}
                    onPreview={handlePreviewImage}
                    maxCount={10}
                    multiple
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {selectedFile.length >= 10
                      ? null
                      : loadingDowloandingFiles === true
                      ? dowLoadButton
                      : uploadButton}
                  </Upload>
                </div>
                <Modal open={openPreview} onCancel={() => setOpenPreview(!openPreview)}>
                  <img alt='example' style={{ width: '100%' }} src={previewImage.image} />
                </Modal>
              </>
            )}
          </Render>
        </Drawer>
      </ViewPage>
    </Container>
  )
}

export default PageNovoPatrimonio
