import React, { useEffect, useState } from 'react'
import {
  delFornecedor,
  getFornecedor,
  getFornecedorEditar,
  postFornecedor,
  putFornecedor,
} from '../../../../services/Fornecedores'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { TabelaFornecedores } from './components/tabela'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { Container } from './styled'
import ViewPage from '../../../../Components/ViewPage'
import { Drawer, Modal } from 'antd'
import ButtonCustom from '../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faXmark } from '@fortawesome/free-solid-svg-icons'
import { NavegacaoDrawer } from '../../recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'
import { buscaCidade, decodeHtml, listUf } from '../../../../services/Afins'
import { getServico } from '../../../../services/Servicos'
import FormFornecedores from '../../../../Components/novoFornecedor'
import { IconAlert } from '../../../../Components/iconsAlert'
import { Notification } from '../../../../Components/notification'
import { successAlert } from '../../../../utils/alert'
import { substituirAsc } from '../../../../utils'

const NovoFornecedores: React.FC = () => {
  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [timeRenderizar, setTimeRenderizar] = useState(false)
  const [dataFornecedores, setDataForncedores] = useState([])
  const [listarUf, setListarUf] = useState([])
  const [dataCity, setDataCity] = useState([])
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(0)
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)

  //FORM
  const [tab_selecionada_atual, setTab_selecionada_atual] = useState<
    'Pessoa jurídica' | 'Pessoa física'
  >('Pessoa jurídica')
  const [dataProduto, setDataProduto] = useState([])
  const [dataProdutoOriginal, setDataProdutoOriginal] = useState([])
  const [dataServico, setDataServico] = useState([])
  const [tipoProduto, setTipoProduto] = useState('')
  const [dataVigencia, setDataVigencia] = useState('')
  const [tipoServico, setTipoServico] = useState('')
  const [valor, setValor] = useState('')
  const [uf, setUf] = useState('')
  const [ufId, setUfId] = useState(0)
  const [cidade, setCidade] = useState('')
  const [cidadeId, setCidadeId] = useState(0)
  const [cnpj, setCnpj] = useState('')
  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const [celular, setCelular] = useState('')
  const [fixo, setFixo] = useState('')
  const [cep, setCep] = useState('')
  const [bairro, setBairro] = useState('')
  const [endereco, setEndereco] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [razaoSocial, setRazaoSocial] = useState('')
  const [nomeFantasia, setNomeFantasia] = useState('')
  const [ie, setIe] = useState('')
  const [indexInicial, setIndexInicial] = useState(null)

  const listarFornecedor = async () => {
    setLoadingTable(true)
    const response = await getFornecedor({
      codCategoria: 1,
      codConta: localStorage.getItem('conta'),
      codCliente: localStorage.getItem('cod-cliente'),
      pagination: pagination,
      page: page,
    })
    const resUF = await listUf()
    setListarUf(resUF)

    setLoadingTable(false)
    setDataForncedores(response.data.itens)
    setTotal(response.data.total)
  }

  const listarFornecedorEditar = async () => {
    setLoadingForm(true)
    const response = await getFornecedorEditar(1, update)
    const ufEditar = listarUf.filter(
      (item) => item.cod === response.data.itens[0]?.cidade[0]?.cod_uf
    )
    setIndexInicial(response.data.itens[0]?.tipo === 'J' ? 0 : 1)
    setNome(substituirAsc(decodeHtml(response.data.itens[0]?.nome)))
    if (response.data.itens[0]?.cpf_cnpj?.length === 18) {
      setCnpj(response.data.itens[0]?.cpf_cnpj)
    } else {
      setCpf(response.data.itens[0]?.cpf_cnpj)
    }
    setTab_selecionada_atual(
      response.data.itens[0]?.tipo === 'J' ? 'Pessoa jurídica' : 'Pessoa física'
    )
    setRazaoSocial(response.data.itens[0]?.razao_social)
    setEndereco(response.data.itens[0]?.endereco)
    setComplemento(response.data.itens[0]?.complemento)
    setCep(response.data.itens[0]?.cep?.toString())
    setCelular(response.data.itens[0]?.fone)
    setFixo(response.data.itens[0]?.fone2)
    setEmail(response.data.itens[0]?.email)
    setNumero(response.data.itens[0]?.endereco_numero)
    setBairro(response.data.itens[0]?.bairro)
    setUfId(response.data.itens[0]?.cidade[0]?.cod_uf)
    setCidade(response.data.itens[0]?.cidade[0]?.nome)
    setCidadeId(response.data.itens[0]?.cidade[0]?.cod)
    setIe(response?.data?.itens[0]?.inscricao_estadual?.toString())
    const dados = response.data.itens[0]?.tags
    if (dados?.length > 0) {
      setDataProduto(dados?.filter((item) => item !== ''))
    }
    setUf(ufEditar[0]?.sigla)

    setLoadingForm(false)
  }

  async function cadastrarFornecedores() {
    // const produtosArray = verificarPalavraComposta(tipoProduto)
    setLoadingSalvar(true)
    const data = {
      nome: nome,
      cpf_cnpj: !cpf ? cnpj : cpf,
      tipo: tab_selecionada_atual === 'Pessoa jurídica' ? 'J' : 'F',
      razao_social: razaoSocial,
      endereco: endereco,
      complemento: complemento,
      cep: cep,
      cod_cidade: cidadeId,
      fone: celular,
      fone2: fixo,
      email: email,
      status: 1,
      endereco_numero: numero,
      bairro: bairro,
      inscricao_estadual: ie,
      tags: dataProduto.length <= 0 ? null : dataProduto,
    }
    if (update <= 0) {
      const response = await postFornecedor(data, fecharCadastro, setLoadingSalvar)
    } else {
      const response = await putFornecedor(update, data, fecharCadastro, setLoadingSalvar)
    }
  }

  const listServico = async () => {
    const response = await getServico('')
  }

  async function listarCidade() {
    const resCity = await buscaCidade('', '', '')
    setDataCity(resCity)
  }

  function abrirCadastro() {
    setOpen(true)
  }

  function fecharCadastro() {
    setOpen(false)
    setTimeRenderizar(false)
    setLoadingForm(false)
    setLoadingSalvar(false)
    setDataProduto([])
    setDataProdutoOriginal([])
    listarFornecedor()
    setUpdate(0)
    setNome('')
    setCpf('')
    setCnpj('')
    setRazaoSocial('')
    setEndereco('')
    setComplemento('')
    setCep('')
    setCelular('')
    setFixo('')
    setEmail('')
    setNumero('')
    setBairro('')
    setUfId(0)
    setCidade('')
    setCidadeId(0)
    setUf('')
    setIe('')
    setTab_selecionada_atual('Pessoa jurídica')
    setIndexInicial(null)
  }

  const handleTrashFornecedor = async (data) => {
    setFake_loading(true)
    const responseDel = await delFornecedor(data)

    if (responseDel.status === 200) {
      successAlert('Excluido com sucesso!')
      listarFornecedor()
    }
    setTimeout(() => {
      setFake_loading(false)
    }, 3000)
  }

  useEffect(() => {
    listarFornecedor()
    listServico()
    listarCidade()
  }, [])

  useEffect(() => {
    if (update > 0) {
      listarFornecedorEditar()
      setOpen(true)
    }
  }, [update])

  return (
    <Container>
      <ViewPage
        title='Fornecedores'
        btnClick={null}
        newHide
        rotina={27}
        onPermission={setPermissions}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Fornecedores</TitleH1>
        </div>

        <TabelaFornecedores
          loading={loadingTable}
          list_fornecedores={dataFornecedores}
          deletar={handleTrashFornecedor}
          abrir={abrirCadastro}
          page={page}
          setPage={setPage}
          setPagination={setPagination}
          total={total}
          editar={setUpdate}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          nome_item_atual={nome_item_atual}
          setNome_item_atual={setNome_item_atual}
          fake_loading={fake_loading}
          setFake_loading={setFake_loading}
          permissao={permissions}
        />

        <Drawer
          width={700}
          open={open}
          onClose={fecharCadastro}
          closable={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '10px 10px' }}
          destroyOnClose
          footer={
            <div className='footer'>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                type='primary'
                onClick={cadastrarFornecedores}
                loading={loadingSalvar}
                disabled={loadingSalvar}
              >
                Salvar
              </ButtonCustom>
            </div>
          }
          title={
            <NavegacaoDrawer>
              <ButtonCustom
                danger
                icon={<FontAwesomeIcon icon={faXmark} />}
                type='tertiary'
                onClick={fecharCadastro}
                style={{ marginLeft: -10 }}
              >
                Fechar
              </ButtonCustom>
            </NavegacaoDrawer>
          }
        >
          <FormFornecedores
            loadingForm={loadingForm}
            tab_selecionada_atual={tab_selecionada_atual}
            setTab_selecionada_atual={setTab_selecionada_atual}
            dataProduto={dataProduto}
            setDataProduto={setDataProduto}
            tipoProduto={tipoProduto}
            setTipoProduto={setTipoProduto}
            dataServico={dataServico}
            setDataServico={setDataServico}
            dataVigencia={dataVigencia}
            setDataVigencia={setDataVigencia}
            tipoServico={tipoServico}
            setTipoServico={setTipoServico}
            valor={valor}
            setValor={setValor}
            uf={uf}
            setUf={setUf}
            ufId={ufId}
            setUfId={setUfId}
            cidade={cidade}
            setCidade={setCidade}
            cidadeId={cidadeId}
            setCidadeId={setCidadeId}
            cnpj={cnpj}
            setCnpj={setCnpj}
            listarUF={listarUf}
            listarCidade={dataCity}
            nome={nome}
            setNome={setNome}
            cpf={cpf}
            setCpf={setCpf}
            email={email}
            setEmail={setEmail}
            celular={celular}
            setCelular={setCelular}
            fixo={fixo}
            setFixo={setFixo}
            cep={cep}
            setCep={setCep}
            bairro={bairro}
            setBairro={setBairro}
            endereco={endereco}
            setEndereco={setEndereco}
            numero={numero}
            setNumero={setNumero}
            complemento={complemento}
            setComplemento={setComplemento}
            razaoSocial={razaoSocial}
            setRazaoSocial={setRazaoSocial}
            nomeFantasia={nomeFantasia}
            setNomeFantasia={setNomeFantasia}
            timeRenderizar={timeRenderizar}
            setTimeRenderizar={setTimeRenderizar}
            ie={ie}
            setIe={setIe}
            update={update}
            indexInicial={indexInicial}
            atualizar={listarFornecedorEditar}
          />
        </Drawer>
      </ViewPage>
    </Container>
  )
}

export default NovoFornecedores