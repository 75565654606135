import { Drawer, Upload } from 'antd/lib'
import React, { useEffect, useRef, useState } from 'react'
import { ContainerEquipe, FormContainer, HeaderFechar } from './styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpFromBracket,
  faFileLines,
  faFloppyDisk,
  faKitMedical,
  faList,
  faLock,
  faMap,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import Tabs from '../Tabs'
import { useScreenSize } from '../../utils/tsx'
import { Button, Modal, UploadProps } from 'antd'
import Svg from '@ant-design/icons'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UploadChangeParam } from 'antd/es/upload'
import FormMembroEquipe from './components/membroEquipe'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import FormDadosComplementares from './components/dadosComplementares'
import FormDocumentos from './components/documentos'
import ButtonCustom from '../ButtonCustom'
import FormSaude from './components/saude'
import FormAcessoPermissao from './components/acessosPermissoes'
import FormAcessoPropriedade from './components/acessosPropriedade'
import dayjs, { Dayjs } from 'dayjs'
import ImgCrop from 'antd-img-crop'
import {
  getEquipe,
  getEquipeFiles,
  postEquipe,
  putEquipe,
  TypesEquipe,
} from '../../services/Equipe'
import { AxiosResponse } from 'axios'
import { useAccess } from '../../context/useAccess'
import {
  getTipoLiderEquipe,
  TypesTipoLiderEquipe,
} from '../../services/consultoriaAgricola/tipoLiderEquipe'
import {
  getTipoConsultor,
  TypesTipoConsultor,
} from '../../services/consultoriaAgricola/tipoConsultor'
import { cepMask, tryError } from '../../services/Afins'
import { getCliente } from '../../services/Cliente'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

interface Props {
  abrir: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  atualizar?: () => void
  permissons?: ItensPerUserLogged
  update?: number
  emailNew?: string
  equipeGeral?: any[]
}

interface DocumentosProps {
  doc_cpf: any[]
  doc_rg: any[]
  doc_titulo: any[]
  doc_cnh: any[]
  doc_pis: any[]
  doc_reservista: any[]
  doc_diploma: any[]
  doc_certidao: any[]
  doc_ctps: any[]
}

interface PropDocumentoSus {
  doc_sus: any[]
}

export function obterLarguraTabResponsiva(
  tab_selecionada: string,
  width: number,
  dafault: number
): number {
  const largurasTabs = {
    'Membro equipe': { md_1536: 150, lg_1920: 280, lg_1366: 160, lg_1280: 150 },
    'Dados complementares': { md_1536: 205, lg_1920: 315, lg_1366: 220, lg_1280: 205 },
    Documentos: { md_1536: 121, lg_1920: 220, lg_1366: 140, lg_1280: 121 },
    Saúde: { md_1536: 80, lg_1920: 190, lg_1366: 100, lg_1280: 80 },
    'Acessos e permissões': { md_1536: 190, lg_1920: 280, lg_1366: 200, lg_1280: 190 },
    'Acessos a propriedades': { md_1536: 205, lg_1920: 300, lg_1366: 216, lg_1280: 205 },
  }

  const config = largurasTabs?.[tab_selecionada]

  if (config) {
    if (width === 1366) {
      return config.lg_1366
    } else if (width === 1280) {
      return config.lg_1280
    } else if (width === 1920) {
      return config.lg_1920
    } else if (width < 1500) {
      return config.md_1536
    }
  }

  return dafault || 125
}

const NovaEquipe: React.FC<Props> = ({
  abrir,
  permissons,
  onClose,
  update,
  atualizar,
  emailNew,
  equipeGeral,
}) => {
  const { currentWidth } = useScreenSize({})
  const { listDefault, loggedInAs } = useAccess()
  const [tab_selecionada, setTab_selecionada] = useState<string>('Membro equipe')
  const [fileList, setFileList] = useState([])
  const [listDataCity, setListDataCity] = useState([])
  const [listDataProfissao, setListDataProfissao] = useState([])
  const [listDataCliente, setListDataCliente] = useState([])
  const [dataLideres, setDataLideres] = useState([])
  const [dataConsultores, setDataConsultores] = useState([])
  const [codFunc, setCodFunc] = useState(null)
  const [targetKey, setTargetKey] = useState([])
  const [accessCliente, setAccessCliente] = useState<number>()
  const [nomeLider, setNomeLider] = useState('')
  const [nomeLiderCopia, setNomeLiderCopia] = useState('')
  const [liderEquipe, setLiderEquipe] = useState(null)
  const [possuiVinculo, setPossuiVinculo] = useState(false)
  const [cargoTecnico, setCargoTecnico] = useState(false)
  const [cargoAt, setCargoAt] = useState(false)
  const [allClientes, setAllClientes] = useState(false)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [travaCep, setTravaCep] = useState(false)
  const [dadosOriginais, setDadosOriginais] = useState({})

  // MEMBRO EQUIPE
  const [nome, setNome] = useState('')
  const [dataNascimento, setDataNascimento] = useState(null)
  const [sexo, setSexo] = useState('')
  const [email, setEmail] = useState('')
  const [escolaridade, setEscolaridade] = useState('')
  const [escolaridadeId, setEscolaridadeId] = useState<number>()
  const [celular, setCelular] = useState('')
  const [telefone, setTelefone] = useState('')
  const [nacionalidade, setNacionalidade] = useState('')
  const [cep, setCep] = useState('')
  const [estadoNascimento, setEstadoNascimento] = useState('')
  const [estadoNascimentoId, setEstadoNascimentoId] = useState<number>()
  const [cidadeNascimento, setCidadeNascimento] = useState('')
  const [cidadeNascimentoId, setCidadeNascimentoId] = useState<number>()
  const [estado, setEstado] = useState('')
  const [estadoId, setEstadoId] = useState<number>()
  const [cidade, setCidade] = useState('')
  const [cidadeId, setCidadeId] = useState<number | string>()
  const [bairro, setBairro] = useState('')
  const [rua, setRua] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')

  //DADOS COMPLEMENTARES
  const [profissao, setProfissao] = useState('')
  const [profissaoId, setProfissaoId] = useState<number>()
  const [estadoCivil, setEstadoCivil] = useState('')
  const [estadoCivilId, setEstadoCivilId] = useState<number>()
  const [tipoUniao, setTipoUniao] = useState('')
  const [tipoUniaoId, setTipoUniaoId] = useState<number>()
  const [nomeConjuge, setNomeConjuge] = useState('')
  const [cpfConjuge, setCpfConjuge] = useState('')
  const [RgConjuge, setRgConjuge] = useState('')
  const [estadoNascimentoConjuge, setEstadoNascimentoConjuge] = useState('')
  const [estadoNascimentoConjugeId, setEstadoNascimentoConjugeId] = useState<number>()
  const [cidadeNascimentoConjuge, setCidadeNascimentoConjuge] = useState('')
  const [cidadeNascimentoConjugeId, setCidadeNascimentoConjugeId] = useState<number>()
  const [nomeMae, setNomeMae] = useState('')
  const [celularMae, setCelularMae] = useState('')
  const [nomePai, setNomePai] = useState('')
  const [celularPai, setCelularPai] = useState('')
  const [obs, setObs] = useState('')

  //DOCUMENTOS
  const [cpf, setCpf] = useState('')
  const [rg, setRg] = useState('')
  const [ufRg, setUfRg] = useState('')
  const [diplomas, setDiplomas] = useState('')
  const [reservista, setReservista] = useState('')
  const [tipoReservista, setTipoReservista] = useState('')
  const [cnh, setCnh] = useState('')
  const [categoriaCnh, setCategoriaCnh] = useState('')
  const [validadeCnh, setValidadeCnh] = useState('')
  const [pis, setPis] = useState('')
  const [ctps, setCtps] = useState('')
  const [serieCTPS, setSerieCTPS] = useState('')

  //SAUDE
  const [tipoSanguineo, setTipoSanguineo] = useState('')
  const [cartaoSus, setCartaoSus] = useState('')
  const [planoParticular, setPlanoParticular] = useState('')
  const [cartaoParticular, setCartaoParticular] = useState('')
  const [documentoSus, setDocumentoSus] = useState<PropDocumentoSus>()

  //ACESSO E PERMISSÕES
  const [perfilAcessoConsultoria, setPerfilAcessoConsultoria] = useState('')
  const [perfilAcessoConsultoriaId, setPerfilAcessoConsultoriaId] = useState<number>()
  const [perfilAcessoClientesGeral, setPerfilAcessoClientesGeral] = useState('')
  const [perfilAcessoClientesGeralId, setPerfilAcessoClientesGeralId] = useState<number>()
  const [perfilAcessoClientes, setPerfilAcessoClientes] = useState('')
  const [perfilAcessoClientesId, setPerfilAcessoClientesId] = useState<number>()
  const [codConsultor, setCodConsultor] = useState<number>()
  const [acessoTodosClientes, setAcessoTodosClientes] = useState('')
  const [dataClienteLiberados, setDataClienteLiberados] = useState([])
  const [targetKey_referencia, setTargetKey_referencia] = useState([])
  const [comAcesso, setComAcesso] = useState([])
  const [semAcesso, setSemAcesso] = useState([])
  const [updateKey, setUpdateKey] = useState(0)

  //ACESSO A PROPRIEDADES
  const [clienteSelecionado, setClienteSelecionado] = useState('')
  const [clienteSelecionadoId, setClienteSelecionadoId] = useState<number>()
  const [limparInput, setLimparInput] = useState(true)

  const [documents, setDocuments] = useState<DocumentosProps>()

  const refFoto = useRef<UploadProps>({} as UploadProps)

  const onChange = ({ fileList: newFileList }: UploadChangeParam) => {
    setFileList(newFileList)
  }

  function onRemove() {
    setFileList([])
  }

  const dataListCitys = async () => {
    const resp = await listDefault({ type: 'CITY' })
    setListDataCity(resp)
  }

  const listaProfissao = async () => {
    const resp = await listDefault({ type: 'PF' })

    setListDataProfissao(resp)
  }

  const listCliente = async () => {
    const resp = await getCliente('', '', '','')

    setListDataCliente(resp.data.itens)
    // message.destroy()
  }

  const onPreview = async (file) => {
    let src = file?.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file?.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }

    Modal.info({
      title: 'Foto',
      width: '500px',
      okText: 'Fechar',
      icon: <Svg component={() => <FontAwesomeIcon icon={regular('image')} />} />,
      content: <img width='100%' src={src} alt='foto' />,
    })
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onChangeDocuments = async (tipo, data: TypesEquipe = {} as TypesEquipe) => {
    if (tipo === null) {
      const usuario = data.itens[0].usuario[0]
      const codEquipe = data.itens[0].cod

      let temp = documents
      Object.entries(usuario).forEach(async ([key, value]) => {
        const oldImg = String(value).split('_')

        if (oldImg.length <= 1) {
          if (
            key === 'doc_certidao' ||
            key === 'doc_pis' ||
            key === 'doc_reservista' ||
            key === 'doc_cpf' ||
            key === 'doc_rg' ||
            key === 'doc_cnh' ||
            key === 'doc_ctps' ||
            key === 'doc_sus' ||
            key === 'doc_titulo' ||
            key === 'doc_diploma'
          ) {
            if (value !== null) {
              const foto = await getEquipeFiles({
                doc: value,
                // @ts-ignore
                docType: key,
                cod: codEquipe,
              })

              temp = { ...temp, [key]: [foto.fileString] }
            }
          }
        }

        setDocuments(temp)
      })
    } else {
      setDocuments({ ...documents, ...tipo })
    }
  }

  const editEquipe = async (cod) => {
    loadingAlert({msg: 'Carregando...', id: 'edit_equipe'});
    const resp: AxiosResponse<TypesEquipe> = await getEquipe({
      cod,
      embed: `usuario,clientes,tipo_assistente_tecnico,tipo_tecnico`,
    })

    const codEquipe = resp.data.itens[0].cod
    const usuario = resp.data.itens[0].usuario[0]
    setAccessCliente(resp.data.itens[0].acesso_cliente)

    setNome(usuario?.nome)
    setDataNascimento(usuario?.nascimento !== null ? dayjs(usuario?.nascimento) : null)
    setSexo(usuario?.sexo)
    setEmail(usuario?.email)
    setEscolaridadeId(usuario?.cod_escolaridade)
    setCelular(usuario?.celular)
    setTelefone(usuario?.fone)
    setNacionalidade(usuario?.nacionalidade)
    setCep(cepMask(usuario?.cep))
    setRua(usuario?.endereco)
    setBairro(usuario?.endereco_bairro)
    setNumero(usuario?.endereco_numero)
    setComplemento(usuario?.complemento)
    setCidadeNascimentoId(parseInt(usuario?.cid_nasc))
    setCidadeId(usuario?.cod_cidade)
    setProfissaoId(usuario?.cod_profissao)
    setEstadoCivilId(usuario?.cod_estado_civil)
    setNomeConjuge(usuario?.conjuge)
    setTipoUniao(usuario?.tipo_uniao)
    setCidadeNascimentoConjugeId(usuario?.cod_conjuge_naturalidade)
    setRgConjuge(usuario?.conjuge_rg)
    setCpfConjuge(usuario?.conjuge_cpf)
    setNomeMae(usuario?.mae)
    setCelularMae(usuario?.fone_mae)
    setNomePai(usuario?.pai)
    setCelularPai(usuario?.fone_pai)
    setCpf(usuario?.cpf)
    setRg(usuario?.rg)
    setUfRg(usuario?.uf_rg)
    setDiplomas(usuario?.diploma)
    setReservista(usuario?.reservista)
    setTipoReservista(usuario?.tipo_reservista)
    setCnh(usuario?.cnh)
    setCategoriaCnh(usuario?.cnh_categoria)
    setValidadeCnh(usuario?.cnh_validade)
    setPis(usuario?.pis)
    setCtps(usuario?.ctps)
    setSerieCTPS(usuario?.ctps_serie)
    setTipoSanguineo(usuario?.tipo_sanguineo)
    setCartaoSus(usuario?.sus)
    setPlanoParticular(usuario?.plano_saude)
    setCartaoParticular(usuario?.num_plano_saude)
    setCodFunc(resp.data.itens[0].cod_func)
    setAccessCliente(resp.data.itens[0].acesso_cliente)
    setObs(usuario?.observacoes)
    imgEquipe(usuario, codEquipe)

    const equipe = resp.data.itens[0]
    setPerfilAcessoClientesGeralId(equipe?.cod_perfil_cliente_geral)
    setPerfilAcessoConsultoriaId(equipe?.cod_perfil_conta)
    setPerfilAcessoClientesId(equipe?.cod_perfil_cliente)
    setAcessoTodosClientes(equipe?.acesso_cliente === 1 ? 'S' : 'N')
    setAllClientes(equipe?.acesso_cliente === 1 ? true : false)
    setCargoTecnico(equipe.tecnico)
    setCargoAt(equipe.assistente_tecnico)
    equipeTecnico(equipe)
    setDataClienteLiberados(equipe.clientes)
    setCodConsultor(equipe.cod_consultor)
    equipeConsultor()

    onChangeDocuments(null, resp.data)

    let clientes: any[] = []
    if (equipe.acesso_cliente === 0 && equipe?.clientes?.length > 0) {
      const temp = equipe.clientes.map((item) => String(item.cod))
      clientes = temp
      setTargetKey(clientes)
      setTargetKey_referencia(clientes)
    } else {
      clientes = []
      setTargetKey([])
      setTargetKey_referencia([])
    }

    const dataOriginal = {
      acesso_cliente: equipe?.acesso_cliente,
      nome: usuario?.nome,
      email: usuario?.email,
      cod_escolaridade: usuario?.cod_escolaridade,
      sexo: usuario?.sexo,
      nascimento: dayjs(usuario?.nascimento),
      celular: usuario?.celular,
      fone: usuario?.fone,
      nacionalidade: usuario?.nacionalidade,
      cliente: equipe?.clientes,
      uf_nasc: usuario?.uf_nasc,
      cid_nasc: usuario?.cid_nasc,
      cep: usuario?.cep,
      endereco: usuario?.endereco,
      endereco_bairro: usuario?.endereco_bairro,
      endereco_numero: usuario?.endereco_numero,
      endereco_cidade: usuario?.endereco_cidade,
      endereco_uf: usuario?.endereco_uf,
      complemento: usuario?.complemento,
      cod_profissao: usuario?.cod_profissao,
      cod_estado_civil: usuario?.cod_estado_civil,
      tipo_uniao: usuario?.tipo_uniao,
      conjuge: usuario?.conjuge,
      conjuge_cpf: usuario?.conjuge_cpf,
      conjuge_rg: usuario?.conjuge_rg,
      uf_conjuge: estadoNascimentoConjugeId,
      cod_conjuge_naturalidade: usuario?.conjuge_naturalidade,
      mae: usuario?.mae,
      fone_mae: usuario?.fone_mae,
      pai: usuario?.pai,
      fone_pai: usuario?.fone_pai,
      observacoes: usuario?.observacoes,
      tipo_sanguineo: usuario?.tipo_sanguineo,
      sus: usuario?.sus,
      plano_saude: usuario?.plano_saude,
      num_plano_saude: usuario?.num_plano_saude,
      cod_perfil_conta: equipe?.cod_perfil_conta,
      cod_perfil_cliente_geral: equipe?.cod_perfil_cliente_geral,
      cod_perfil_cliente: equipe?.cod_perfil_cliente,
      status: 1,
      cpf: usuario?.cpf,
      rg: usuario?.rg,
      uf_rg: usuario?.uf_rg,
      diploma: usuario?.diploma,
      reservista: usuario?.reservista,
      tipo_reservista: usuario?.tipo_reservista,
      cnh: usuario?.cnh,
      cnh_categoria: usuario?.cnh_categoria,
      cnh_validade: usuario?.cnh_validade,
      pis: usuario?.pis,
      ctps: usuario?.ctps,
      ctps_serie: usuario?.ctps_serie,
      
    }
    setDadosOriginais(dataOriginal)
    destroyAlert('edit_equipe');
  }

  async function equipeTecnico(data) {
    const response: AxiosResponse<TypesTipoLiderEquipe> = await getTipoLiderEquipe({})

    const dados = response.data.itens.map((item) => {
      return { label: item.usuario_nome, value: item.cod_usuario }
    })

    setDataLideres(dados)

    const lider = equipeGeral.filter((item) => item.cod_func === data.cod_consultor)

    if (lider.length > 0) {
      const nameLider = lider[0].usuario_nome

      setNomeLider(nameLider)
      setNomeLiderCopia(nameLider)
      setLiderEquipe(lider[0].cod_func)
      setPossuiVinculo(true)
    } else {
      console.error('Líder não encontrado ou não possui usuario_nome.')
    }
  }

  async function equipeConsultor() {
    const resp: AxiosResponse<TypesTipoConsultor> = await getTipoConsultor({})
    const dados = resp.data.itens.map((item) => {
      return { label: item.usuario_nome, value: item.cod_usuario }
    })

    setDataConsultores(dados)
  }

  function compararObjetos(objOriginal, objAlterado) {
    const diferencas = {}

    // Itera sobre as chaves do objeto original
    for (const key in objOriginal) {
      if (objOriginal.hasOwnProperty(key)) {
        // Verifica se o valor de cada chave é diferente
        if (objOriginal[key] !== objAlterado[key]) {
          // Verifica se o valor alterado não é nulo, vazio ou indefinido
          if (
            objAlterado[key] !== null &&
            objAlterado[key] !== '' &&
            objAlterado[key] !== undefined
          ) {
            diferencas[key] = objAlterado[key] // Retorna somente o valor alterado
          }
        }
      }
    }

    // Retorna as diferenças ou null se forem iguais
    return Object.keys(diferencas).length > 0 ? diferencas : null
  }

  function removerRecemAdicionados(clientes_remover: string[]) {
    // return clientes_remover;
    if (targetKey_referencia?.length === 0) {
      return []
    }
    return clientes_remover.filter((element) => targetKey_referencia.includes(element))
  }

  function limparStates() {
    setNome('')
    setDataNascimento(null)
    setSexo('')
    setEmail('')
    setEscolaridade('')
    setEscolaridadeId(null)
    setCelular('')
    setTelefone('')
    setNacionalidade('')
    setCep('')
    setEstadoNascimento('')
    setEstadoNascimentoId(null)
    setCidadeNascimento('')
    setCidadeNascimentoId(null)
    setBairro('')
    setRua('')
    setNumero('')
    setComplemento('')
    setProfissao('')
    setProfissaoId(null)
    setEstadoCivil('')
    setEstadoCivilId(null)
    setTipoUniao('')
    setTipoUniaoId(null)
    setNomeConjuge('')
    setCpfConjuge('')
    setRgConjuge('')
    setEstadoNascimentoConjuge('')
    setEstadoNascimentoConjugeId(null)
    setCidadeNascimentoConjuge('')
    setCidadeNascimentoConjugeId(null)
    setEstado('')
    setEstadoId(null)
    setCidade('')
    setCidadeId(null)
    setNomeMae('')
    setCelularMae('')
    setNomePai('')
    setCelularPai('')
    setObs('')
    setCpf('')
    setRg('')
    setUfRg('')
    setDiplomas('')
    setReservista('')
    setTipoReservista('')
    setCnh('')
    setCategoriaCnh('')
    setValidadeCnh('')
    setPis('')
    setCtps('')
    setSerieCTPS('')
    setTipoSanguineo('')
    setCartaoSus('')
    setPlanoParticular('')
    setCartaoParticular('')
    setPerfilAcessoClientes('')
    setPerfilAcessoClientesId(null)
    setPerfilAcessoClientesGeral('')
    setPerfilAcessoClientesGeralId(null)
    setObs('')
    setLiderEquipe('')
    setNomeLider('')
    setNomeLiderCopia('')
    setAcessoTodosClientes('')
    setClienteSelecionado('')
    setClienteSelecionadoId(null)
    setLimparInput(false)
    setLoadingSalvar(false)
    setPossuiVinculo(false)
    setFileList([])
    setDocuments({
      doc_cpf: [],
      doc_rg: [],
      doc_titulo: [],
      doc_cnh: [],
      doc_pis: [],
      doc_reservista: [],
      doc_diploma: [],
      doc_certidao: [],
      doc_ctps: [],
    })
    setDocumentoSus({
      doc_sus: [],
    })
    setSemAcesso([])
    setComAcesso([])
  }

  function validarCampos() {
    if (!nome) {
      errorAlert('Verifique o campo nome!')
      setLoadingSalvar(false)
      return null
    }

    if (!email) {
      errorAlert('Verifique o campo email')

      setLoadingSalvar(false)
      return null
    }

    if (!cpf) {
      errorAlert('Verifique o campo cpf');

      setLoadingSalvar(false)
      return null
    }

    if (!sexo) {
      errorAlert('Verifique o campo sexo!');
      setLoadingSalvar(false)
      return null
    }

    if (!nacionalidade) {
      errorAlert('Verifique o campo nacionalidade');
      setLoadingSalvar(false)
      return null
    }

    if (!estadoNascimento) {
      errorAlert('Verifique o campo estado nascimento!');
      setLoadingSalvar(false)
      return null
    }

    if (!cidadeNascimento) {
      errorAlert('Verifique o campo cidade nascimento!');
      setLoadingSalvar(false)
      return null
    }

    if (!rua) {
      errorAlert('Verifique o campo logradouro!')
      setLoadingSalvar(false)
      return null
    }
    onFinish()
    setLoadingSalvar(true)
  }

  async function onFinish() {
  
    try {

      loadingAlert({msg: 'Salvando...', id: 'salvando_nova_equipe'});

      let changedData: any = {}

      const data = {
        nome: nome,
        email: email,
        cod_escolaridade: escolaridadeId,
        sexo: sexo,
        nascimento: dayjs(dataNascimento).format('DD/MM/YYYY'),
        celular: celular,
        fone: telefone,
        nacionalidade: nacionalidade,
        uf_nasc: estadoNascimentoId,
        cid_nasc: cidadeNascimentoId,
        cep: cep,
        endereco: rua,
        endereco_bairro: bairro,
        endereco_numero: numero,
        endereco_cidade: cidadeId,
        cod_cidade: cidadeId,
        complemento: complemento,
        cod_profissao: profissaoId,
        cod_estado_civil: estadoCivilId,
        tipo_uniao: tipoUniao,
        conjuge: nomeConjuge,
        conjuge_cpf: cpfConjuge,
        conjuge_rg: RgConjuge,
        uf_conjuge: estadoNascimentoConjugeId,
        cod_conjuge_naturalidade: cidadeNascimentoConjugeId,
        mae: nomeMae,
        fone_mae: celularMae,
        pai: nomePai,
        fone_pai: celularPai,
        observacoes: obs,
        tipo_sanguineo: tipoSanguineo,
        sus: cartaoSus,
        plano_saude: planoParticular,
        num_plano_saude: cartaoParticular,
        cod_perfil_conta: perfilAcessoConsultoriaId,
        cod_perfil_cliente_geral: perfilAcessoClientesGeralId,
        cod_perfil_cliente: perfilAcessoClientesId,
        status: 1,
        cpf: cpf,
        rg: rg,
        uf_rg: ufRg,
        diploma: diplomas,
        reservista: reservista,
        tipo_reservista: tipoReservista,
        cnh: cnh,
        cnh_categoria: categoriaCnh,
        cnh_validade: validadeCnh,
        pis: pis,
        ctps: ctps,
        ctps_serie: serieCTPS,
        doc_foto: fileList[0]?.originFileObj,
        doc_cpf_arquivo: documents?.doc_cpf['file']?.originFileObj,
        doc_rg_arquivo: documents?.doc_rg['file']?.originFileObj,
        doc_pis_arquivo: documents?.doc_pis['file']?.originFileObj,
        doc_cnh_arquivo: documents?.doc_cnh['file']?.originFileObj,
        doc_reservista_arquivo: documents?.doc_reservista['file']?.originFileObj,
        doc_diploma_arquivo: documents?.doc_diploma['file']?.originFileObj,
        doc_sus_arquivo: documentoSus?.doc_sus['file']?.originFileObj,
      }

      const resultado = compararObjetos(dadosOriginais, data)
      changedData = resultado

      if (semAcesso.length > 0 && update > 0) {
        changedData.cliente_remover = removerRecemAdicionados(semAcesso)
      }

      if (update > 0) {
        // @ts-ignore
        if (JSON.stringify(changedData.cliente) === JSON.stringify(dadosOriginais.cliente)) {
          changedData.cliente = comAcesso
        } else {
          if (comAcesso.length > 0) {
            changedData.cliente = comAcesso
          } else {
            changedData.cliente = []
          }

          if (semAcesso.length > 0) {
            changedData.cliente_remover = removerRecemAdicionados(semAcesso)
          } else {
            changedData.cliente_remover = []
          }
        }
        changedData.acesso_cliente = acessoTodosClientes === 'S' ? 1 : 0
      }

      if (data.doc_foto !== fileList?.[0]?.originFileObj?.name) {
        changedData.doc_foto_arquivo = fileList?.[0]?.originFileObj
      }

      if (update <= 0) {
        const response = await postEquipe(data)
        if (response.status === 201) {
          successAlert('Membro da equipe cadastrado com sucesso!', 5000, 'salvando_nova_equipe');
          limparStates()
          setLoadingSalvar(false)
          handleClose()
          atualizar()
          setDocuments({
            doc_cpf: [],
            doc_rg: [],
            doc_titulo: [],
            doc_cnh: [],
            doc_pis: [],
            doc_reservista: [],
            doc_diploma: [],
            doc_certidao: [],
            doc_ctps: [],
          })
        }
      } else {
        // console.log('changed:', changedData)
        const response = await putEquipe(update, changedData)

        if (response.status === 200) {
          successAlert('Membro da equipe atualizado com Sucesso!', 5000, 'salvando_nova_equipe');
          atualizar()
          // limparStates()
          setLoadingSalvar(false)
          editEquipe(update)
        }
        else {
          destroyAlert();
        }
      }
    } catch (error) {
      setLoadingSalvar(false)
      tryError(error)
      setLoadingSalvar(false)
      console.log('error: ', error)
    }
  }

  async function imgEquipe(usuario, codEquipe) {
    const imgOld = usuario?.doc_foto?.split('_')

    if (imgOld?.length <= 1) {
      try {
        const foto = await getEquipeFiles({
          doc: usuario.doc_foto,
          docType: 'doc_foto',
          cod: codEquipe,
        })

        const fileFoto = {
          uid: usuario.doc_foto,
          status: 'done',
          response: 'done',
          name: usuario.doc_foto,
          url: foto.url,
          preview: foto.fileString,
          thumbUrl: foto.fileString,
        }

        // @ts-ignore
        onChange({ fileList: [fileFoto] })
      } catch (error) {
        console.log(error)
      }
    }
  }

  function handleClose() {
    onClose(false)
    setTab_selecionada('Membro equipe')
    limparStates()
  }

  useEffect(() => {
    if (abrir) {
      setEmail(emailNew)

      if (update > 0) {
        editEquipe(update)
      }
    }
  }, [abrir])

  useEffect(() => {
    if (updateKey > 0) {
      editEquipe(update)
    }
  }, [updateKey])

  useEffect(() => {
    listaProfissao()
    dataListCitys()
    listCliente()
  }, [])

  return (
    <Drawer
      open={abrir}
      size='default'
      placement='right'
      width='100%'
      getContainer={false}
      closeIcon={false}
      destroyOnClose
    >
      <ContainerEquipe>
        <HeaderFechar>
          <ButtonCustom
            danger
            icon={<FontAwesomeIcon icon={faXmark} />}
            type='tertiary'
            onClick={() => handleClose()}
            style={{ marginLeft: -10 }}
          >
            Fechar
          </ButtonCustom>
        </HeaderFechar>
        <FormContainer>
          <Tabs
            tabs={[
              {
                descricao: 'Membro equipe',
                icone: faUser,
                width: obterLarguraTabResponsiva('Membro equipe', currentWidth, 190),
              },
              {
                descricao: 'Dados complementares',
                icone: faList,
                width: obterLarguraTabResponsiva('Dados complementares', currentWidth, 243),
              },
              {
                descricao: 'Documentos',
                icone: faFileLines,
                width: obterLarguraTabResponsiva('Documentos', currentWidth, 165),
              },
              {
                descricao: 'Saúde',
                icone: faKitMedical,
                width: obterLarguraTabResponsiva('Saúde', currentWidth, 135),
              },
              {
                descricao: 'Acessos e permissões',
                icone: faLock,
                width: obterLarguraTabResponsiva('Acessos e permissões', currentWidth, 232),
                disabled: codFunc === null,
              },
              {
                descricao: 'Acessos a propriedades',
                icone: faMap,
                width: obterLarguraTabResponsiva('Acessos a propriedades', currentWidth, 237),
                disabled:
                  codFunc === null ||
                  loggedInAs?.type === 'CLIENTE' ||
                  loggedInAs?.type === 'CONTA_CLIENTE',
              },
            ]}
            selecionado={tab_selecionada}
            onChange={setTab_selecionada}
            trackWidth={148}
            useTabWidth
            apenasIcones={currentWidth < 1280}
          />
          <div className='form-inputs'>
            <div className='campo-foto'>
              <div className='foto-profile'>
                <ImgCrop modalOk='Salvar' modalTitle='Foto' aspect={500 / 500} rotationSlider>
                  <Upload
                    ref={refFoto}
                    listType='picture-card'
                    className='foto-upload'
                    customRequest={dummyRequest}
                    fileList={fileList}
                    maxCount={1}
                    accept='image/png, image/jpeg, image/jpg'
                    onChange={onChange}
                    onPreview={onPreview}
                    onRemove={onRemove}
                  >
                    {fileList.length < 1 && (
                      <div className='desc-foto'>
                        <FontAwesomeIcon icon={faArrowUpFromBracket} className='icone-upload' />
                        <p className='desc-foto-p'>Enviar foto de perfil</p>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {update > 0 && <p className='nome-usuario'>{nome}</p>}
              </div>
            </div>
            <div className='campo-inputs'>
              {tab_selecionada === 'Membro equipe' && (
                <FormMembroEquipe
                  permissions={permissons}
                  nomeCompleto={nome}
                  setNomeCompleto={setNome}
                  dataNascimento={dataNascimento}
                  setDataNascimento={setDataNascimento}
                  sexo={sexo}
                  setSexo={setSexo}
                  email={email}
                  setEmail={setEmail}
                  escolaridade={escolaridade}
                  escolaridadeId={escolaridadeId}
                  setEscolaridade={setEscolaridade}
                  setEscolaridadeId={setEscolaridadeId}
                  celular={celular}
                  setCelular={setCelular}
                  telefone={telefone}
                  setTelefone={setTelefone}
                  nacionalidade={nacionalidade}
                  setNacionalidade={setNacionalidade}
                  cep={cep}
                  setCep={setCep}
                  estadoNascimento={estadoNascimento}
                  estadoNascimentoID={estadoNascimentoId}
                  setEstadoNascimento={setEstadoNascimento}
                  setEstadoNascimentoID={setEstadoNascimentoId}
                  cidadeNascimento={cidadeNascimento}
                  cidadeNascimentoID={cidadeNascimentoId}
                  setCidadeNascimento={setCidadeNascimento}
                  setCidadeNascimentoID={setCidadeNascimentoId}
                  estado={estado}
                  estadoID={estadoId}
                  setEstado={setEstado}
                  setEstadoID={setEstadoId}
                  cidade={cidade}
                  cidadeID={cidadeId}
                  setCidade={setCidade}
                  setCidadeID={setCidadeId}
                  bairro={bairro}
                  setBairro={setBairro}
                  rua={rua}
                  setRua={setRua}
                  numero={numero}
                  setNumero={setNumero}
                  complemento={complemento}
                  setComplemento={setComplemento}
                  update={update}
                  open={abrir}
                  listaCidades={listDataCity}
                  setLoading={setTravaCep}
                ></FormMembroEquipe>
              )}
              {tab_selecionada === 'Dados complementares' && (
                <FormDadosComplementares
                  permissions={permissons}
                  profissao={profissao}
                  setProfissao={setProfissao}
                  profissaoId={profissaoId}
                  setProfissaoId={setProfissaoId}
                  estadoCivil={estadoCivil}
                  setEstadoCivil={setEstadoCivil}
                  estadoCivilId={estadoCivilId}
                  setEstadoCivilId={setEstadoCivilId}
                  tipoUniao={tipoUniao}
                  setTipoUniao={setTipoUniao}
                  tipoUniaoId={tipoUniaoId}
                  setTipoUniaoId={setTipoUniaoId}
                  nomeConjuge={nomeConjuge}
                  setNomeConjuge={setNomeConjuge}
                  cpfConjuge={cpfConjuge}
                  setCpfConjuge={setCpfConjuge}
                  RgConjuge={RgConjuge}
                  setRgConjuge={setRgConjuge}
                  estadoNascimentoConjuge={estadoNascimentoConjuge}
                  setEstadoNascimentoConjuge={setEstadoNascimentoConjuge}
                  estadoNascimentoConjugeID={estadoNascimentoConjugeId}
                  setEstadoNascimentoConjugeID={setEstadoNascimentoConjugeId}
                  cidadeNascimentoConjuge={cidadeNascimentoConjuge}
                  cidadeNascimentoConjugeID={cidadeNascimentoConjugeId}
                  setCidadeNascimentoConjuge={setCidadeNascimentoConjuge}
                  setCidadeNascimentoConjugeID={setCidadeNascimentoConjugeId}
                  nomeMae={nomeMae}
                  setNomeMae={setNomeMae}
                  celularMae={celularMae}
                  setCelularMae={setCelularMae}
                  nomePai={nomePai}
                  setNomePai={setNomePai}
                  celularPai={celularPai}
                  setCelularPai={setCelularPai}
                  observacao={obs}
                  setObservacao={setObs}
                  listaCidades={listDataCity}
                  listarProfissoes={listDataProfissao}
                  update={update}
                ></FormDadosComplementares>
              )}
              {tab_selecionada === 'Documentos' && (
                <FormDocumentos
                  permissions={permissons}
                  cpf={cpf}
                  setCpf={setCpf}
                  rg={rg}
                  setRg={setRg}
                  ufRg={ufRg}
                  setUfRg={setUfRg}
                  diplomas={diplomas}
                  setDiplomas={setDiplomas}
                  reservista={reservista}
                  setReservista={setReservista}
                  tipoReservista={tipoReservista}
                  setTipoReservista={setTipoReservista}
                  cnh={cnh}
                  setCnh={setCnh}
                  validadeCnh={validadeCnh}
                  categoriaCnh={categoriaCnh}
                  setCategoriaCnh={setCategoriaCnh}
                  setValidadeCnh={setValidadeCnh}
                  pis={pis}
                  setPis={setPis}
                  ctps={ctps}
                  setCtps={setCtps}
                  serieCTPS={serieCTPS}
                  setSerieCTPS={setSerieCTPS}
                  documentos={documents}
                  setDocumentos={setDocuments}
                ></FormDocumentos>
              )}
              {tab_selecionada === 'Saúde' && (
                <FormSaude
                  permissions={permissons}
                  tipoSanguineo={tipoSanguineo}
                  setTipoSanguineo={setTipoSanguineo}
                  cartaoSus={cartaoSus}
                  setCartaoSus={setCartaoSus}
                  planoSaude={planoParticular}
                  setPlanoSaude={setPlanoParticular}
                  cartaoParticular={cartaoParticular}
                  setCartaoParticular={setCartaoParticular}
                  documentoSus={documentoSus}
                  setDocumentosSus={setDocumentoSus}
                ></FormSaude>
              )}
              {tab_selecionada === 'Acessos e permissões' && (
                <FormAcessoPermissao
                  permissions={permissons}
                  perfilAcessoConsultoria={perfilAcessoConsultoria}
                  setPerfilAcessoConsultoria={setPerfilAcessoConsultoria}
                  perfilAcessoConsultoriaId={perfilAcessoConsultoriaId}
                  setPerfilAcessoConsultoriaId={setPerfilAcessoConsultoriaId}
                  perfilAcessoAosClientesGeral={perfilAcessoClientesGeral}
                  perfilAcessoAosClientesGeralId={perfilAcessoClientesGeralId}
                  setPerfilAcessoAosClientesGeral={setPerfilAcessoClientesGeral}
                  setPerfilAcessoAosClientesGeralId={setPerfilAcessoClientesGeralId}
                  perfilAcessoAosClientes={perfilAcessoClientes}
                  perfilAcessoAosClientesId={perfilAcessoClientesId}
                  setPerfilAcessoAosClientes={setPerfilAcessoClientes}
                  setPerfilAcessoAosClientesId={setPerfilAcessoClientesId}
                  acessoTodosClientes={acessoTodosClientes}
                  setAcessoTodosClientes={setAcessoTodosClientes}
                  liderConsultores={nomeLider}
                  liderConsultoresID={liderEquipe}
                  liderConsultoresCopia={nomeLiderCopia}
                  setLiderConsultoresID={setLiderEquipe}
                  setLiderConsultores={setNomeLider}
                  targetKeyAcesso={targetKey}
                  setTargetKeyAcesso={setTargetKey}
                  update={update}
                  cargoTecnico={cargoTecnico}
                  cargoAt={cargoAt}
                  dataLiders={dataLideres}
                  dataConsultores={dataConsultores}
                  dataClienteLiberados={dataClienteLiberados}
                  codFunc={codFunc}
                  codConsultor={codConsultor}
                  usuario={nome}
                  possuiVinculo={possuiVinculo}
                  setPossuiVinculo={setPossuiVinculo}
                  nomeLider={nomeLiderCopia}
                  setNomeLider={setNomeLiderCopia}
                  updateKey={updateKey}
                  setUpdateKey={setUpdateKey}
                  comAcesso={comAcesso}
                  setComAcesso={setComAcesso}
                  semAcesso={semAcesso}
                  setSemAcesso={setSemAcesso}
                ></FormAcessoPermissao>
              )}
              {tab_selecionada === 'Acessos a propriedades' && (
                <FormAcessoPropriedade
                  permissions={permissons}
                  clienteSelecionado={clienteSelecionado}
                  setClienteSelecionado={setClienteSelecionado}
                  clienteSelecionadoId={clienteSelecionadoId}
                  setClienteSelecionadoId={setClienteSelecionadoId}
                  targetKeys={targetKey}
                  setTargetKeys={setTargetKey}
                  codFunc={codFunc}
                  allClients={allClientes}
                  listDataCliente={listDataCliente}
                  accessCliente={accessCliente}
                  limparInput={limparInput}
                  setLimparInput={setLimparInput}
                ></FormAcessoPropriedade>
              )}
            </div>
          </div>
          <div className='btn-salvar-form'>
            <ButtonCustom
              icon={<FontAwesomeIcon icon={faFloppyDisk} />}
              onClick={validarCampos}
              loading={loadingSalvar}
              disabled={loadingSalvar || travaCep}
            >
              Salvar
            </ButtonCustom>
          </div>
        </FormContainer>
      </ContainerEquipe>
    </Drawer>
  )
}

export default NovaEquipe
