import React, { useEffect, useState } from 'react'

import { Form, Input, Drawer, Button, Col, Row, Select, Radio } from 'antd'

import {
  postCatPatrimonio,
  putCatPatrimonio,
  getCatPatrimonio,
} from '../../services/config/categoriasPatrimonio'


import { Container } from './styled'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

interface TypesFormCatPatrimonio {
  onShow: boolean
  onClose: (data: boolean) => void
  update: number
  setUpdate: (data: number) => void
  refresh: (data: string) => void
}

const { Option } = Select

const CatPatrimonio: React.FC<TypesFormCatPatrimonio> = (props) => {
  const { update, setUpdate, onShow, onClose, refresh } = props

  const [form] = Form.useForm()

  const [drawerVisible, setDrawerVisible] = useState(false)

  const [dataCategorias, setDataCategorias] = useState([])

  const listCategorias = async () => {
    const response = await getCatPatrimonio({
      order: 'nome',
      pagination: -1,
      permiteMarca: true,
    })

    setDataCategorias(response.data.itens)
  }

  const editCatPatrimonio = async (data) => {
    loadingAlert({msg: 'Carregando...', id: 'cat_patri'})
    const response = await getCatPatrimonio({
      cod: data,
      pagination: -1,
      order: 'nome',
    })

    const dados = response.data.itens[0]

    form.setFieldsValue(dados)
    destroyAlert('cat_patri')
  }

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setUpdate(0)
    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      data.exibir_marca = data.exibir_marca ? 1 : 0

      if (update <= 0) {
        loadingAlert({msg: 'Cadastrando categoria...', id: 'cadastro_categoria'})

        const response = await postCatPatrimonio(data)
        if (response.status === 201) {
          successAlert('Categoria patrimônio cadastrada com sucesso', 5000, 'cadastro_categoria')
          form.resetFields()
          refresh('')
        }
      } else {
        loadingAlert({msg: 'Atualizando categoria...', id: 'editar_categoria'})
        const response = await putCatPatrimonio(update, data)
        if (response.status === 200) {
          successAlert('Categoria patrimônio atualizada com sucesso', 5000, 'editar_categoria')
          refresh('')
          hancleDrawerClose();
        }
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha ao registrar categoria!', 7000, 'editar_categoria')
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao registrar categoria!', 7000, 'editar_categoria')
          })
        }
      }
      else {
        errorAlert('Falha ao salvar categoria!', 5000, 'editar_categoria')
      }
    }
  }

  useEffect(() => {
    setDrawerVisible(onShow)
  }, [onShow])

  useEffect(() => {
    if (update !== 0) {
      editCatPatrimonio(update)
    }
  }, [update])

  useEffect(() => {
    listCategorias()
  }, [])

  return (
    <Container>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Drawer
          title={
            update <= 0 ? 'Cadastro de Categoria Patrimônio' : 'Atualizar Categoria Patrimônio'
          }
          style={{ position: 'absolute' }}
          placement='right'
          width='100%'
          closeIcon={false}
          open={drawerVisible}
          onClose={hancleDrawerClose}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button type='primary' htmlType='submit'>
                {update ? 'Atualizar Categoria Patrimônio' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          <Col span={22}>
            <Row gutter={[8, 8]}>
              <Col>
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name='nome'
                  label='Nome Categoria Patrimônio'
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col flex='0 1 300px'>
                <Form.Item
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  name='cod_categoria'
                  label='Categoria'
                >
                  <Select
                    style={{ minWidth: '100%' }}
                    showSearch
                    placeholder='Selecione uma Categoria'
                    allowClear
                    filterOption={(input, option) =>
                      // @ts-ignore
                      option.children.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={null}>
                        Principal
                    </Option>
                    {(dataCategorias).map((data) => (
                      <Option key={data.cod} value={data.cod}>
                        {data.nome.slice(0, 1) + data.nome.slice(1).toLowerCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label='Listar no teste de Bandeja' name='teste_bandeja'>
                  <Radio.Group defaultValue={0}>
                    <Radio key={1} value={1}>
                      Sim
                    </Radio>
                    <Radio key={2} value={0}>
                      Não
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {/* <Col span={24} flex="1 1 300">
                <Form.Item label="Exibir em Marcas" name="exibir_marca">
                  <Switch
                    onChange={handleExibirMarca}
                    checkedChildren="Sim"
                    unCheckedChildren="Não"
                    checked={exibirMarca}
                  />
                </Form.Item>
              </Col> */}
            </Row>
          </Col>
        </Drawer>
      </Form>
    </Container>
  )
}

export default CatPatrimonio
