import React, { useEffect, useState } from 'react'
import ViewPage from '../../../Components/ViewPage'

import { Button, message, Modal, Space, Table } from 'antd'
import {
  ItensSharedFilesUser,
  delArquivoCompartilhado,
  getSharedFilesUser,
} from '../../../services/SharedFiles'
import { Container } from './styles'

import Icon, { ReloadOutlined } from '@ant-design/icons'
import { faCopy, faDownLong, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { pagination } from '../../../Components/Default'
import Icons from '../../../Components/Icons'
import { filterTable, tryError } from '../../../services/Afins'
import { ItensFilesUser, getFilesUser } from '../../../services/SharedFiles/users'
import { api } from '../../../services/api'
import ButtonCustom from '../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Carregando from '../../../Components/CustomUi/loading'
import { Render } from '../../../Components/CustomUi/render'
import { TitleH3 } from '../../../Components/CustomUi/titleH3'

const SharedFiles: React.FC = () => {
  const [sharedFiles, setSharedFiles] = useState<ItensSharedFilesUser[]>([])
  const [sharedFilesFilter, setSharedFilesFilter] = useState([])
  const [users, setUsers] = useState<ItensFilesUser[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [loadingTxt, setLoadingTxt] = useState(false)
  const [open, setOpen] = useState(false)
  const [visuTxt, setVisuTxt] = useState('')

  const listSharedFiles = async () => {
    setLoading(true)
    const resp = await getSharedFilesUser({ pagination: -1 })

    const dadosAtt = resp.data.itens.map((item) => {
      const tipo = item.nome.split('.').pop()

      return { ...item, tipo: tipo }
    })
    setSharedFiles(dadosAtt)
    setSharedFilesFilter(dadosAtt)
    setLoading(false)
  }

  const listUsers = async () => {
    const resp = await getFilesUser({ ordem: 'create_time', pagination: -1 })
    setUsers(resp.data.itens)
  }

  async function visualizarTxt(cod: number) {
    setLoadingTxt(true)
    setOpen(true)
    const respe = await api.get(`/compartilhamento-arquivo/${cod}/visualizar-arquivo`)
    setVisuTxt(respe?.data)
    setLoadingTxt(false)
  }

  const copyToClipboard = () => {
    setLoadingBtn(true)
    if (!visuTxt) return

    navigator.clipboard
      .writeText(visuTxt)
      .then(() => {
        message.success({ content: 'Texto copiado', duration: 5 })
        setTimeout(() => {
          setLoadingBtn(false)
        }, 500)
      })
      .catch((err) => {
        message.error({ content: `Erro ao copiar texto: ${err}`, duration: 5 })
        setTimeout(() => {
          setLoadingBtn(false)
        }, 500)
      })
  }

  const handleDownload = async (cod: number, fileName) => {
    try {
      const resp = await api.get(`/compartilhamento-arquivo/${cod}/visualizar-arquivo`, {
        responseType: 'blob',
      })

      const href = URL.createObjectURL(resp.data)

      const link = document.createElement('a')

      link.href = href

      link.download = fileName

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)

      URL.revokeObjectURL(href)
    } catch (error) {
      tryError(error)
    }
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (Object.values(filters).every((f) => f === null)) {
      setSharedFilesFilter(sharedFiles)
    } else {
      setSharedFilesFilter(extra.currentDataSource)
    }
  }

  async function handleTrase(cod) {

    const resp = await delArquivoCompartilhado(cod)

    listSharedFiles()
  }

  useEffect(() => {
    listUsers()
    listSharedFiles()
  }, [])

  return (
    <Container>
      <ViewPage
        newHide
        title='ttivos® Drive'
        btnsExta={
          <Button
            type='primary'
            onClick={listSharedFiles}
            loading={loading}
            icon={<ReloadOutlined />}
          >
            Atualizar
          </Button>
        }
      >
        <Table
          loading={loading}
          dataSource={orderBy(
            sharedFiles,
            (data) => {
              return dayjs(data.create_time).unix()
            },
            'desc'
          )}
          size='small'
          onChange={handleTableChange}
          pagination={pagination}
          scroll={{ y: 'calc(100vh - 370px)' }}
          bordered
          columns={[
            {
              title: 'Data',
              width: 170,
              sorter: (data) => dayjs(data.create_time).unix(),
              dataIndex: 'create_time',
              render: (data: number) => dayjs(data).format('DD/MM/YYYY HH:mm:ss'),
            },
            {
              title: 'Usuário',

              dataIndex: 'usuario_nome',
              render: (data) => data,
            },
            { title: 'Descrição', dataIndex: 'descricao' },
            {
              title: 'Arquivo',

              dataIndex: 'nome',
              ...filterTable(false, true, 'tipo', sharedFiles),
            },
            {
              title: 'Ações',
              dataIndex: 'cod',
              width: 135,
              align: 'center',
              render: (cod: number, record) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Space>
                    <Button
                      shape='circle'
                      type='default'
                      onClick={() => handleDownload(cod, record.arquivo)}
                      icon={<Icon component={() => <Icons icon={faDownLong} />} />}
                    />
                  </Space>
                  {record.tipo === 'txt' ? (
                    <Space>
                      <Button
                        shape='circle'
                        type='default'
                        onClick={() => visualizarTxt(cod)}
                        icon={<Icon component={() => <Icons icon={faEye} />} />}
                      />
                    </Space>
                  ) : null}
                  <Space>
                    <Button
                      shape='circle'
                      type='default'
                      onClick={() => handleTrase(cod)}
                      icon={<Icon component={() => <Icons icon={faTrash} />} />}
                      style={{ backgroundColor: 'red', border: 'none' }}
                    />
                  </Space>
                </div>
              ),
            },
          ]}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total: {sharedFilesFilter.length}</b>
            </div>
          )}
        />
        <Modal
          open={open}
          onCancel={() => {
            setOpen(false)
          }}
          footer={false}
          style={{ top: '30px' }}
          bodyStyle={{ padding: '10px 20px 0px 20px' }}
          width={'70%'}
          destroyOnClose
        >
          <Render condition={loadingTxt}>
            <Carregando animation legenda='Carregando dados...' justifyContent='center' />
          </Render>

          <Render condition={loadingTxt === false}>
            <div>
              <h3>Conteúdo do arquivo:</h3>
            </div>
            <div
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                background: '#f4f4f4',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                maxHeight: '500px',
                margin: 'auto',
                overflow: 'auto',
              }}
            >
              <pre
                style={{
                  whiteSpace: 'pre-wrap', // Mantém formatação e permite quebra de linha
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  marginBottom: '10px',
                  lineHeight: '2.0',
                  gap: '20px',
                  fontSize: '13px',
                }}
              >
                {visuTxt}
              </pre>
            </div>
            <div style={{marginTop: '15px', display: 'flex', justifyContent: 'flex-end'}}>
              <ButtonCustom
                icon={<FontAwesomeIcon icon={faCopy} />}
                disabled={loadingBtn}
                loading={loadingBtn}
                onClick={copyToClipboard}
              >
                Copiar
              </ButtonCustom>
            </div>
          </Render>
        </Modal>
      </ViewPage>
    </Container>
  )
}

export default SharedFiles
