import React, { useEffect, useState } from 'react'
import { FormContainer } from '../timeline/styled'
import Input from '../../../CustomUi/input'
import { Select } from '../../../CustomUi/Select'
import {
  faCode,
  faFloppyDisk,
  faList,
  faPen,
  faPlus,
  faTrash,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextArea from '../../../CustomUi/textarea'
import Checkbox from '../../../CustomUi/checkbox'
import ButtonCustom from '../../../ButtonCustom'
import { getLogs, getUsuarioLog, postLogs, putLogs } from '../../../../services/timeLine'
import { faGitAlt } from '@fortawesome/free-brands-svg-icons'
import { cores, dadosFilter } from '../timeline/meta'
import { Notification } from '../../../notification'
import { localConfig } from '../../../../Configs/localConfig'
import { decodeHtml } from '../../../../services/Afins'
import { Tag } from 'antd'
import { errorAlert, infoAlert } from '../../../../utils/alert'

interface Props {
  fecharModal?: () => void
  update?: number
  dadosLogs?: any[]
}

const FormTimeline: React.FC<Props> = ({ fecharModal, update, dadosLogs }) => {
  const [tipoLog, setTipoLog] = useState<number>()
  const [tipoLogNew, setTipoLogNew] = useState<number>()
  const [usuarioLogId, setUsuarioLogId] = useState<number>()
  const [nomeLog, setNomeLog] = useState<string>()
  const [nomeLogNew, setNomeLogNew] = useState<string>()
  const [titulo, setTitulo] = useState<string>()
  const [descricao, setDescricao] = useState('')
  const [descricaoNew, setDescricaoNew] = useState('')
  const [branch, setBranch] = useState<string>()
  const [usuarioLogNome, setUsuarioLogNome] = useState<string>()
  const [editarDesc, setEditarDesc] = useState(null)
  const [usuarioLog, setUsuarioLog] = useState([])
  const [selectTipo, setSelectTipo] = useState(false)
  const [selectTipoNew, setSelectTipoNew] = useState(false)
  const [selectUsuario, setSelectUsuario] = useState(false)
  const [checkBox, setCheckBox] = useState(false)
  const [addNewLog, setAddNewLog] = useState(false)
  const [itensLogs, setItensLogs] = useState([])
  const [itensRemovidos, setItensRemovidos] = useState([])
  const [filtros, setFiltros] = useState([])
  const [versaoUp, setVersaoUp] = useState('')

  const { acesso } = localConfig()

  const listUsuarioLog = async () => {
    const response = await getUsuarioLog()
    setUsuarioLog(response.data.itens)
  }

  async function onFinish() {
    const arrayLogs = itensLogs.map((item) => {
      if (item?.cod?.length > 10) {
        return {
          ...item,
          cod: null, // Define cod como null se o comprimento for maior que 10
        }
      }
      return item
    })

    if (update > 0) {
      const dados = {
        titulo: titulo,
        descricao: descricao,
        aplicacao: 1,
        tipo: tipoLog,
        branch: branch,
        cod_usuario: usuarioLogId,
        logs: arrayLogs,
        versao: versaoUp,
        logs_remover: itensRemovidos,
      }
      const response = await putLogs(
        dados,
        update,
        setTitulo,
        setDescricao,
        setTipoLog,
        setBranch,
        setItensLogs,
        setDescricaoNew,
        setCheckBox,
        setNomeLog,
        setUsuarioLogNome,
        setAddNewLog,
        fecharModal
      )
    } else {
      const dados = {
        titulo: titulo,
        descricao: descricao,
        aplicacao: 1,
        tipo: tipoLog,
        branch: branch,
        cod_usuario: usuarioLogId,
        logs: arrayLogs,
        version: process.env.REACT_APP_VERSION,
      }
      const response = await postLogs(
        dados,
        setTitulo,
        setDescricao,
        setTipoLog,
        setBranch,
        setItensLogs,
        setDescricaoNew,
        setCheckBox,
        setNomeLog,
        setUsuarioLogNome,
        setAddNewLog
      )
    }
  }

  const gerenciarCod = (item, editarDesc, update) => {
    if (item.cod === editarDesc) {
      const updatedItem = {
        ...item,
        descricao: descricaoNew,
        privado: checkBox,
        tipo: tipoLogNew,
        tipo_nome: nomeLogNew,
      }

      if (update > 0) {
        updatedItem.cod = editarDesc // Mantém `cod` se `update > 0`
      }
      return updatedItem
    }

    return item
  }

  function handleInserir() {
    if (editarDesc !== null) {
      const itensAtualizados = itensLogs.map((item) => gerenciarCod(item, editarDesc, update))
      setItensLogs(itensAtualizados)
      setEditarDesc(null)
      setCheckBox(true)
    } else {
      if (descricaoNew == '') {
        infoAlert('Preencha a descrição', 3000);
      } else {
        setItensLogs([
          ...itensLogs,
          {
            cod: Date.now().toString(), // ID único baseado no timestamp atual
            descricao: descricaoNew,
            privado: checkBox,
            tipo: tipoLogNew,
            tipo_nome: nomeLogNew,
          },
        ])
      }
    }
    setDescricaoNew('')
    setNomeLogNew('')
    setCheckBox(false)
  }

  const handleEditar = (cod) => {
    const item = itensLogs.find((item) => item.cod === cod)
    const nomesTipo = filtros.filter((rec) => rec.cod === item.tipo)
    setDescricaoNew(item.descricao)
    setCheckBox(item.privado)
    setEditarDesc(cod)
    setTipoLogNew(item?.tipo)
    setNomeLogNew(item?.tipo_nome)
  }

  const handleRemover = (cod) => {
    const itemRemovido = itensLogs.find((item) => item.cod === cod)
    const itensFiltrados = itensLogs.filter((item) => item.cod !== cod)

    if (itemRemovido) {
      setItensRemovidos((prevItens) => [...prevItens, itemRemovido.cod])
    }

    setItensLogs(itensFiltrados)
  }

  function substituirAsc(str: string) {
    let remove = str?.replace(/quot;|&amp;/g, '"')

    return remove ?? str
  }

  useEffect(() => {
    if (update > 0) {
      const dadosUpdate = dadosLogs.find((item) => item.cod === update)
      setNomeLog(dadosUpdate.tipo_nome)
      setTipoLog(dadosUpdate.tipo)
      setTitulo(dadosUpdate.titulo)
      setUsuarioLogNome(dadosUpdate.usuario_nome)
      setUsuarioLogId(dadosUpdate.cod_usuario)
      setBranch(dadosUpdate.branch)
      setDescricao(dadosUpdate.descricao)
      setVersaoUp(dadosUpdate.versao)
      if (dadosUpdate.logs.length > 0) {
        setAddNewLog(true)
        setItensLogs(dadosUpdate.logs)
      } else {
        setAddNewLog(false)
      }
    }
  }, [update])

  useEffect(() => {
    listUsuarioLog()
    if ('https://ttivos.app' === process.env.REACT_APP_LINK) {
      const filter = dadosFilter.filter((item) => item.nome !== 'Teste' && item.nome !== 'Docs')
      setFiltros(filter)
    } else {
      setFiltros(dadosFilter)
    }
  }, [])

  return (
    <FormContainer>
      <div className='header-close'>
        <h1 className='title-cadastro'>Cadastrar ChangeLog</h1>
        <button className='close-btn' onClick={fecharModal}>
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: '20px', color: '#9F9F9F' }} />
        </button>
      </div>

      <Select
        value={nomeLog}
        onChange={(e) => setNomeLog(e)}
        onChangeID={(e) => setTipoLog(e)}
        open={selectTipo}
        trigger={setSelectTipo}
        items={filtros}
        descricaoItem='nome'
        idItem='cod'
        label='Selecione o tipo'
        placeholder='Selecione o tipo log'
        icon={faCode}
      ></Select>

      <Input
        label='Título'
        icon={faList}
        placeholder='Digite o título'
        value={titulo}
        onChange={(e) => setTitulo(e)}
      />

      <Select
        value={usuarioLogNome}
        onChange={(e) => setUsuarioLogNome(e)}
        onChangeID={(e) => setUsuarioLogId(e)}
        open={selectUsuario}
        trigger={setSelectUsuario}
        items={usuarioLog}
        descricaoItem='nome'
        idItem='cod'
        label='Usuario'
        placeholder='Selecione o usuario'
        icon={faUser}
      ></Select>

      <Input
        label='Branch'
        icon={faGitAlt}
        placeholder='Digite o nome da branch'
        value={branch}
        onChange={(e) => setBranch(e)}
      />

      {update > 0 ? (
        <Input
          label='Versão'
          icon={faGitAlt}
          placeholder='Digite o nome da branch'
          value={versaoUp}
          onChange={setVersaoUp}
        />
      ) : null}

      <TextArea
        label='Descrição'
        placeholder='Descrição'
        value={descricao}
        onChange={(e) => setDescricao(e)}
      />
      {addNewLog ? (
        <div className='add-new-log'>
          <div className='form-new-log'>
            {itensLogs.length > 0 ? <h3>Logs adicionais</h3> : null}
            {itensLogs.map((item) => {
              return (
                <div className='area-logs-new' key={item.cod}>
                  <div className='logs-new'>
                    <div className='type-descricao'>
                      <p className='descricao-p'>{item.privado ? 'Privado' : ''}</p>

                      <div className='descricao-newlog'>
                        <p className='descricao-p'>{substituirAsc(decodeHtml(item.descricao))}</p>
                      </div>
                      <Tag color={cores[item?.tipo]} className='circle'>
                        {item.tipo_nome}
                      </Tag>
                    </div>
                    <div className='btn-ações'>
                      <button onClick={() => handleEditar(item.cod)}>
                        <FontAwesomeIcon
                          icon={faPen}
                          style={{ fontSize: '13px', color: '#5DA57D' }}
                        />
                      </button>
                      <button className='btn-delete-log' onClick={() => handleRemover(item.cod)}>
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ fontSize: '13px', color: 'red' }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className='add-descricao-new'>
              {itensLogs.length > 0 ? <div className='line-divisor'></div> : null}
              <Checkbox label='Privado' isChecked={checkBox} trigger={setCheckBox} />
              <Select
                value={nomeLogNew}
                onChange={setNomeLogNew}
                onChangeID={setTipoLogNew}
                open={selectTipoNew}
                trigger={setSelectTipoNew}
                items={filtros}
                descricaoItem='nome'
                idItem='cod'
                label='Selecione o tipo'
                placeholder='Selecione o tipo log'
                icon={faCode}
              />
              <TextArea
                label='Descrição'
                value={substituirAsc(decodeHtml(descricaoNew))}
                onChange={(e) => setDescricaoNew(e)}
              />
              <div className='btn-inserir-log'>
                <ButtonCustom
                  type='secondary'
                  icon={
                    editarDesc ? (
                      <FontAwesomeIcon icon={faPen} />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} />
                    )
                  }
                  onClick={handleInserir}
                >
                  {editarDesc ? 'Editar' : 'Inserir'}
                </ButtonCustom>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className='area-btn-add-log'>
        {acesso == 'cliente' ? null : (
          <ButtonCustom
            type='tertiary'
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setAddNewLog(!addNewLog)}
          >
            Adicionar outra descrição
          </ButtonCustom>
        )}
      </div>
      <div className='area-btn-salvar'>
        <ButtonCustom icon={<FontAwesomeIcon icon={faFloppyDisk} />} onClick={() => onFinish()}>
          {update > 0 ? 'Atualizar' : 'Salvar'}
        </ButtonCustom>
      </div>
    </FormContainer>
  )
}

export default FormTimeline
